import { ChangeEvent, useEffect, useState } from "react";
import { Input } from "@mui/material";
import { useAppDispatch } from "../../hooks/redux";
import { addValue } from "../../store/ActionCreators";
import { useDebounce } from "../../hooks/debounce";

export function InputTab(props: {
  value: any;
  index: any;
  data: any;
  handler: string;
  columnid: any;
}) {
  const { value, data, index, columnid, handler } = props;
  const [val, setVal] = useState(value);
  const disinput = handler !== "name_spec" ? !data[index][handler] : undefined;
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(val, 1000);

  async function changeValue(val: string) {
    let valuetem = data.map((el: any) => Object.assign({}, el));
    const res = await val;
    valuetem[index][columnid] = res;
    dispatch(addValue(valuetem));
  }

  useEffect(() => {
    changeValue(debounced);
  }, [debounced]);

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }

  return (
    <Input
      key={index}
      fullWidth
      multiline
      disabled={disinput}
      value={val}
      onChange={changeHandler}
    />
  );
}
