import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Button, Typography } from "@mui/material";
import { userAuthenticatedOut } from "../../store/ActionCreators";
import { useEffect, useState } from "react";

export const AuthStatus = () => {
  const [session, setSession] = useState(false);
  let navigate = useNavigate();
  const { value: user } = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();
  const handle = () => {
    dispatch(userAuthenticatedOut(null));
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    setSession(false);
    navigate("/login");
  };
  useEffect(() => {
    if (user.username !== "") setSession(true);
  }, [user]);

  const handlerlogin = () => {
    // setSession(true);
    navigate("/login");
  };
  return (
    <>
      {!session ? (
        <>
          <Button color="inherit" onClick={handlerlogin}>
            Вход
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" color="info" onClick={handle}>
            Выход
          </Button>
        </>
      )}
    </>
  );
};
