import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../api";

export const templateThunk = <T, P>(func: any, typePrefix: string) => {
  return createAsyncThunk<T, P>(typePrefix, async (value, thunkAPI) => {
    try {
      return func(value);
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  });
};

export const getPreCatalog = templateThunk<any, any>(
  api.getPreCatalog,
  "listPanel/getPreCatalog"
);
export const getPreCatalogNoReg = templateThunk<any, any>(
  api.getPreCatalogNoReg,
  "listPanel/getPreCatalogNoReg"
);
export const setResult = templateThunk<any, any>(
  api.setResult,
  "result/setPreCatalog"
);
export const setResultNoReg = templateThunk<any, any>(
  api.setResultNoReg,
  "result/setResultNoReq"
);
export const loginAccount = templateThunk<any, any>(
  api.loginAccount,
  "user/setUser"
);
export const getProfile = templateThunk<any, any>(
  api.getProfile,
  "user/getProfile"
);
export const createObject = templateThunk<any, any>(
  api.createObject,
  "object/setObject"
);
export const getProject = templateThunk<any, any>(
  api.getProject,
  "object/getProject"
);
export const achiveProject = templateThunk<any, any>(
  api.achiveProject,
  "object/achiveProject"
);
export const achiveOffer = templateThunk<any, any>(
  api.achiveOffer,
  "object/achiveOffer"
);
export const searchProject = templateThunk<any, any>(
  api.searchProject,
  "object/searchProject"
);
export const createOffer = templateThunk<any, any>(
  api.createOffer,
  "offer/setOffer"
);
export const doubleOffer = templateThunk<any, any>(
  api.doubleOffer,
  "offer/doubleOffer"
);
export const getOffer = templateThunk<any, string>(
  api.getOffer,
  "offer/getOfferOne"
);
export const offerFromBuild = templateThunk<any, string>(
  api.offerFromBuild,
  "offer/getObject"
);
export const createCalc = templateThunk<any, any>(
  api.createCalc,
  "offer/setCalc"
);
export const changeCalc = templateThunk<any, any>(
  api.changeCalc,
  "offer/changeCalc"
);
export const changeCountCalc = templateThunk<any, any>(
  api.changeCountCalc,
  "offer/changeCountCalc"
);
export const getCalcFromOffers = templateThunk<any, string>(
  api.getCalcFromOffers,
  "calcOffer/getCalc"
);
export const patchOffer = templateThunk<any, any>(
  api.patchOffer,
  "offer/patchOffer"
);
export const deleteCalcFromOffers = templateThunk<any, any>(
  api.deleteCalcFromOffers,
  "calcOffer/deleteCalc"
);
export const offersFull = templateThunk<any, string>(
  api.offersFull,
  "Offer/getOffersFull"
);
export const offersAll = templateThunk<any, string>(
  api.offersAll,
  "Offer/getoffersAll"
);
// offersAll;
export const getPreCatalogM = templateThunk<any, any>(
  api.getPreCatalogM,
  "precatalog/getPreCatalog"
);
export const setPreCatalogM = templateThunk<any, any>(
  api.setPreCatalogM,
  "precatalog/setPreCatalog"
);
export const getCompany = templateThunk<any, any>(
  api.getCompany,
  "company/getCompany"
);
export const setCompany = templateThunk<any, any>(
  api.setCompany,
  "company/setCompany"
);
export const getAccounts = templateThunk<any, any>(
  api.getAccounts,
  "company/getAccounts"
);
export const changeRole = templateThunk<any, any>(
  api.changeRole,
  "company/setRole"
);
