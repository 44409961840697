import { createSlice } from "@reduxjs/toolkit";
import { TypeEquipmentState } from "../../interfaces";
import { getPreCatalogM, setPreCatalogM } from "../ThunkCreator";

const initialState: TypeEquipmentState = {
  types: [],
  loading: false,
  error: "",
};

export const TypeEquipmentSlice = createSlice({
  name: "typeEquipment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.types = action.payload.type;
    });
    builder.addCase(getPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.types = action.payload.type;
    });
    builder.addCase(setPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default TypeEquipmentSlice.reducer;
