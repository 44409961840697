import { Typography as MUITypography, TypographyProps } from "@mui/material";

export const Typography = (props: TypographyProps) => (
  <MUITypography {...props}>{props.children}</MUITypography>
);
Typography.muiName = "MUITypography";

export const TextSecondary = (props: TypographyProps) => (
  <MUITypography
    sx={{ fontSize: 14 }}
    color="text.secondary"
    gutterBottom
    {...props}
  />
);
TextSecondary.muiName = "MUITypography";
