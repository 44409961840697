import { Box, Grid, Card, TableContainer } from "@mui/material";

import { LayoutCards } from "../../../components/Layout/LayoutCards";
import { LayoutMain } from "../../../components/Layout/LayoutMain";
import { useEmptyCalc } from "../../../hooks";
import { LayoutPanelProps } from "../panel/PanelProps";

export const BoxResult = () => {
  return <Box sx={{ p: 10 }}>Выбери все параметры и загрузи решение</Box>;
};

export const PanelLayout = (props: LayoutPanelProps) => {
  const { panel, preresult, result } = props;
  const isFixed = useEmptyCalc();
  return (
    <LayoutMain>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        justifyContent="flex-end"
      >
        {isFixed ? (
          <Grid item xs={24} sm={24} md={14} lg={16}>
            <LayoutCards>{result}</LayoutCards>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Card
                hidden={false ? true : false}
                raised
                sx={{ minWidth: 275, height: 300, mb: 1, mt: 0, pt: 1, pl: 1 }}
              >
                <TableContainer sx={{ maxHeight: 550 }}>{panel}</TableContainer>
              </Card>
              <Card
                hidden={false ? true : false}
                raised
                sx={{ minWidth: 275, minHeight: 480, mb: 1 }}
              >
                {preresult}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8}>
              <LayoutCards>{result}</LayoutCards>
            </Grid>
          </>
        )}
      </Grid>
    </LayoutMain>
  );
};
