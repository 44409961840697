import { useState } from "react";
import {
  TableCell,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Table,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IPanel, TableRowProps } from "../../interfaces";
import { CollapsePanel } from "./CollapsePanel";
import { SelectDefault } from "./SelectDefault";
import { CustomSwitch } from "./CustomSwitch";

const ariaLabel = { "aria-label": "description" };

interface RowProps {
  row: IPanel;
  labelId: string;
}

export function RowPanel(props: RowProps) {
  const { row, labelId } = props;
  const { value } = useAppSelector((state) => state.PanelReducer);
  const [count] = useState(
    value
      .map((el) => {
        return el.position;
      })
      .filter((val, ind, arr) => arr.indexOf(val) === ind)
  );
  const [position] = useState([...count, Math.max(...count) + 1]);
  const [types] = useState(
    row.meaning.map((el) => {
      return el.name;
    })
  );
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [dis, setDis] = useState<Boolean>(true);

  const handlerdic = () => {
    if (dis === false) {
      setDis(true);
    }
    if (dis === true) {
      setDis(false);
    }
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.name}
        </TableCell>

        <TableCell align="left">
          <SelectDefault
            description={false}
            // changeHandlerType={changeHandlerType}
            type={row.default}
            typechoose={"default"}
            types={types}
            ident={row.id}
          />
        </TableCell>
        <TableCell>
          <SelectDefault
            description={false}
            // changeHandlerType={changeHandlerType}
            type={String(row.position)}
            typechoose={"position"}
            types={position}
            ident={row.id}
          />
        </TableCell>
        <TableCell>
          <CustomSwitch
            closed={row.closed}
            typechoose={"closed"}
            ident={row.id}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <CollapsePanel row={row} />
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
