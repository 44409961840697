import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IAccounts, ICompany } from "../../../interfaces";
import { IRole } from "../AppointRole/AppointRole";

export interface SelectUserProps {
  accounts: IAccounts[];
  role: IRole;
  setRole: React.Dispatch<React.SetStateAction<IRole>>;
  company?: ICompany[];
}

export default function SelectUser(props: SelectUserProps) {
  const { accounts, role, setRole } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setRole({ ...role, user: Number(event.target.value) });
  };

  return (
    <FormControl sx={{ width: "240px", mt: 1, ml: 1 }}>
      <InputLabel id="demo-simple-select-label">Пользователь</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={role.user.toString()}
        label="Роль"
        onChange={handleChange}
      >
        {accounts.map((el) => (
          <MenuItem value={el.id}>{el.username}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
