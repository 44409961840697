//Таблица для необработанного каталога.
import { createSlice } from "@reduxjs/toolkit";
import { CatalogCleanState } from "../../interfaces";
import { getPreCatalogM, setPreCatalogM } from "../ThunkCreator";

const initialState: CatalogCleanState = {
  catalog: [],
  loading: false,
  error: "",
};

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.catalog = action.payload.catalog;
    });
    builder.addCase(getPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.catalog = action.payload.catalog;
    });
    builder.addCase(setPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default catalogSlice.reducer;
