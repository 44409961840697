import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { ChangeEvent, useState, KeyboardEvent } from "react";
import { getProject, searchProject } from "../../store/ThunkCreator";

export const Filter = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handlerClick = () => {
    dispatch(searchProject(search));
  };
  const handlerReset = () => {
    dispatch(getProject("value"));
  };
  const handEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      dispatch(searchProject(search));
    }
  };
  return (
    <>
      <DialogContent sx={{ display: "flex", alignItems: "center" }}>
        {/* <DialogContentText sx={{ mr: 3 }}>Поиск по объекту</DialogContentText> */}
        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",

            flexGrow: 1,
          }}
          variant="outlined"
        >
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="horizontal" /> */}
          <InputBase
            sx={{ flex: 1, backgroundColor: "grey" }}
            value={search}
            onChange={handler}
            onKeyDown={handEnter}
            placeholder="Поиск по объектам"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handlerClick}
          >
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="horizontal" />
        </Paper>
        <DialogActions>
          <Button onClick={handlerClick}>Поиск</Button>
          <Button onClick={handlerReset}>Сбросить</Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};
