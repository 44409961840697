import { createSlice } from "@reduxjs/toolkit";
import { DataState, resultCalcData } from "../../interfaces";
import {
  changeCalc,
  changeCountCalc,
  createCalc,
  deleteCalcFromOffers,
  doubleOffer,
  getCalcFromOffers,
} from "../ThunkCreator";
// import { mokResult } from "./mok";

export type ChangeIcon = {
  idpos: number;
  value: boolean;
};

const initialState: DataState<ChangeIcon[] | []> = {
  value: [],
  loading: false,
  error: "",
};

export const ChangeResultSlice = createSlice({
  name: "calcChangeOffer",
  initialState,
  reducers: {
    addChangeResult(state, action) {
      state.value = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalcFromOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCalcFromOffers.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          value: true,
        };
      });
    });
    builder.addCase(getCalcFromOffers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          value: true,
        };
      });
    });
    builder.addCase(createCalc.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          value: true,
        };
      });
    });
    builder.addCase(changeCountCalc.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeCountCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCountCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          value: true,
        };
      });
    });
    builder.addCase(changeCalc.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ChangeResultSlice.reducer;
