import { axiosApi } from ".";
import { IOffersPatch, resultCalcData } from "../interfaces";
import { saveAs } from "file-saver";
import { IRole } from "../module/Settings/AppointRole/AppointRole";

type CreateUserResponse = {
  access: string;
  refresh: string;
};

export const api = {
  getPreCatalog: async (type: any) => {
    if (type === "1") {
      const resp = await axiosApi.cabinet.get(`/api/v2/panel`);
      return resp.data;
    } else {
      const resp = await axiosApi.cabinet.get(`/api/v2/wpanel`);
      return resp.data;
    }
  },
  getPreCatalogNoReg: async () => {
    const resp = await axiosApi.nonregister.get(`/api/v2/panel`);
    return resp.data;
  },
  setResult: async (value: any) => {
    if (value.type === "1") {
      console.log(value.choose);
      const resp = await axiosApi.cabinet.post(`/api/v2/panel`, {
        choose: value.choose,
      });
      console.log(resp.data.result);
      return resp.data;
    } else {
      const resp = await axiosApi.cabinet.post(`/api/v2/wpanel`, {
        choose: value.choose,
      });
      console.log(resp.data.result);
      return resp.data;
    }
  },
  setResultNoReg: async (value: any) => {
    const resp = await axiosApi.nonregister.post(`/api/v2/panel`, {
      choose: value,
    });
    console.log(resp.data.result);
    return resp.data;
  },
  loginAccount: async (value: any) => {
    const user = { username: value.username, password: value.password };
    const resp = await axiosApi.cabinet.post<CreateUserResponse>(
      `/api/v2/auth/jwt/create/`,
      {
        ...user,
      }
    );
    localStorage.setItem("token", resp.data.access);
    localStorage.setItem("refresh", resp.data.refresh);
    const resptwo = await axiosApi.cabinet.get(`/api/v2/auth/users/me`);
    const respthree = await axiosApi.cabinet.get(
      `/api/v2/account/${resptwo.data.id}`
    );
    return respthree.data;
  },
  registerAccount: async (value: any) => {
    console.log(value);
    try {
      const user = {
        username: value.username,
        password: value.password,
        email: value.email,
      };
      const resp = await axiosApi.instance.post<CreateUserResponse>(
        `/api/v2/auth/users/`,
        {
          ...user,
        }
      );
      return resp;
    } catch (error) {
      return error;
    }
  },
  getProfile: async (value: any) => {
    const resptwo = await axiosApi.cabinet.get(`/api/v2/auth/users/me`);
    const respthree = await axiosApi.cabinet.get(
      `/api/v2/account/${resptwo.data.id}`
    );
    return respthree.data;
  },
  createObject: async (value: any) => {
    const resp = await axiosApi.cabinet.post(`/api/v2/object/`, {
      name: value.name,
      company: value.company,
      created_by: value.user,
    });
    const finish = await axiosApi.cabinet.get(`/api/v2/showobject/`);
    return finish.data;
  },

  getProject: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/showobject/`);
    console.log(resp.data);
    return resp.data;
  },
  searchProject: async (value: any) => {
    const resp = await axiosApi.cabinet.get(
      `/api/v2/showobject/?name=${value}`
    );
    return resp.data;
  },
  achiveProject: async (value: { id: string; publish: boolean }) => {
    const change = await axiosApi.cabinet.patch(`/api/v2/object/${value.id}/`, {
      is_published: !value.publish,
    });
    const resp = await axiosApi.cabinet.get(`/api/v2/showobject/`);
    return resp.data;
  },
  achiveOffer: async (value: {
    id: number;
    publish: boolean;
    offer: number;
  }) => {
    const change = await axiosApi.cabinet.patch(`/api/v2/offers/${value.id}/`, {
      is_published: !value.publish,
    });
    const resp = await axiosApi.cabinet.get(
      `/api/v2/offerforbuild/${value.offer}`
    );
    return resp.data;
  },
  createOffer: async (value: any) => {
    const resp = await axiosApi.cabinet.post(`/api/v2/createoffer/`, {
      type: value.type,
      created_by: value.createdBy,
      obj_build: value.objBuild,
    });
    return resp.data;
  },
  doubleOffer: async (value: any) => {
    const resp = await axiosApi.cabinet
      .get(`/api/v2/createoffer/${value.id.toString()}`)
      .then((v) =>
        axiosApi.cabinet.post(`/api/v2/createoffer/`, {
          type: v.data.type,
          created_by: v.data.created_by,
          obj_build: v.data.obj_build,
          comment: "",
        })
      );
    const resptwo = await axiosApi.cabinet.post(
      `/api/v2/calcset/`,
      value.data.map((l: resultCalcData) => {
        return {
          name: l.name,
          price: l.size.toString(),
          count: l.count.toString(),
          total_price: l.density.toString(),
          offers: resp.data.id,
          positions: l.specification,
          choose: l.choose,
        };
      })
    );
    const respthree = await axiosApi.cabinet.get(
      `/api/v2/calcfromoffer/${resp.data.id.toString()}/`
    );

    return { ...respthree.data, newoffer: resp.data };
  },
  getOffer: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/createoffer/${value}`);
    return resp.data;
  },
  offerFromBuild: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/offerforbuild/${value}`);
    console.log(resp.data);
    return resp.data;
  },
  createCalc: async (value: any) => {
    const resp = await axiosApi.cabinet
      .post(`/api/v2/calculate/`, {
        name: value.name,
        price: value.price.toFixed(2).toString(),
        count: value.count,
        total_price: value.totalPrice.toFixed(2).toString(),
        offers: value.offers,
        positions: value.positions,
        choose: value.choose,
      })
      .then(() =>
        axiosApi.cabinet.get(
          `/api/v2/calcfromoffer/${value.offers.toString()}/`
        )
      );
    return resp.data;
  },
  changeCalc: async (value: any) => {
    const resp = await axiosApi.cabinet
      .patch(`/api/v2/calculate/${value.idpos}/`, {
        name: value.name,
        price: value.price.toFixed(2).toString(),
        count: value.count,
        total_price: value.totalPrice.toFixed(2).toString(),
        offers: value.offers,
        positions: value.positions,
        choose: value.choose,
      })
      .then(() =>
        axiosApi.cabinet.get(
          `/api/v2/calcfromoffer/${value.offers.toString()}/`
        )
      );
    return resp.data;
  },
  changeCountCalc: async (value: any) => {
    const resp = await axiosApi.cabinet
      .patch(`/api/v2/calculate/${value.idpos}/`, {
        count: value.count,
      })
      .then(() =>
        axiosApi.cabinet.get(
          `/api/v2/calcfromoffer/${value.offers.toString()}/`
        )
      );
    return resp.data;
  },
  getCalcFromOffers: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/calcfromoffer/${value}/`);
    return resp.data;
  },
  patchOffer: async (value: any) => {
    const resp = await axiosApi.cabinet.patch(
      `/api/v2/updateoffer/${value.id}`,
      {
        summ: value.summ.toFixed(2).toString(),
        discount: value.discount.toFixed(2).toString(),
        price: value.price.toFixed(2).toString(),
      }
    );
    return resp.data;
  },
  patchComment: async (value: any) => {
    const resp = await axiosApi.cabinet.patch(`/api/v2/offers/${value.id}/`, {
      comment: value.comment,
    });
    return resp.data;
  },
  deleteCalcFromOffers: async (value: any) => {
    const resp = await axiosApi.cabinet.delete(`/api/v2/calculate/${value}/`);
    return value;
  },
  offersFull: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/offersfull/`);
    return resp.data;
  },
  offersAll: async (value: any) => {
    const resp = await axiosApi.cabinet.get(`/api/v2/offersall/`);
    return resp.data;
  },
  //Для локальной логики без стора
  createObjectSimple: async (value: any) => {
    const resp = await axiosApi.cabinet.post(`/api/v2/object/`, {
      name: value.name,
      company: value.company,
      created_by: value.user,
    });
    return resp.data;
  },
  patchOfferSimple: async (value: IOffersPatch) => {
    const resp = await axiosApi.cabinet.patch(
      `/api/v2/calcfromoffer/${value.id}/`,
      {
        summ: value.summ,
        discount: value.discount,
        price: value.price,
      }
    );
  },
  burnPDF: (forpdf: string | undefined) => {
    axiosApi.cabinet.get(`/api/v2/pdf/${forpdf}`);
  },
  createCalcNew: async (value: resultCalcData[], id: number) => {
    const resp = await axiosApi.cabinet.post(
      `/api/v2/calcset/`,
      value.map((el) => ({
        name: el.name,
        price: el.size,
        count: el.count,
        total_price: el.density,
        offers: id,
        positions: el.specification,
      }))
    );
    return resp.data;
  },
  savePDF: (forpdf: string | undefined) => {
    axiosApi.cabinet
      .get(`/api/v2/pdf/${forpdf}`, { responseType: "blob" })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "коммерческое предложение.pdf");
      });
  },
  // getPDF: async (forpdf: string | undefined) => {
  //   let link = axiosApi.cabinet
  //     .get(`/api/v2/pdf/${forpdf}`, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       const pdfBlob = new Blob([res.data], { type: "application/pdf" });
  //       return URL.createObjectURL(pdfBlob);
  //       // return new Blob([res.data], { type: "application/pdf" });
  //     })
  //     .then((e) => {
  //       let linss = document.createElement("a");
  //       linss.setAttribute("target", "_blank");
  //       linss.href = e;
  //       linss.click();
  //       return e;
  //     });
  //   return await link;

  //   // return link;
  //   // const pdfBlob = await new Blob([r.data], { type: "application/pdf" });
  //   // return await URL.createObjectURL(pdfBlob);
  // },
  getPDF: async (forpdf: string | undefined) => {
    let link = axiosApi.cabinet
      .get(`/media/order_0${forpdf}.pdf`, {
        responseType: "blob",
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        return URL.createObjectURL(pdfBlob);
      })
      .then((e) => {
        let linss = document.createElement("a");
        linss.setAttribute("target", "_blank");
        linss.href = e;
        linss.click();
        return e;
      });
    return await link;
  },
  getPreCatalogM: async (type: any) => {
    if (type === "1") {
      const resp = await axiosApi.cabinet.get(`/api/v2/calc`);
      return resp.data;
    } else {
      const resp = await axiosApi.cabinet.get(`/api/v2/wcalc`);
      return resp.data;
    }
  },
  setPreCatalogM: async (value: any) => {
    if (value.type === "1") {
      const resp = await axiosApi.cabinet.post(`/api/v2/calc`, {
        value: value.value,
        calculate: value.calculate,
        listchange: value.listchange,
        panel: value.panel,
      });
      const resptwo = await axiosApi.cabinet.get(`/api/v2/calc`);
      return resptwo.data;
    } else {
      const resp = await axiosApi.cabinet.post(`/api/v2/wcalc`, {
        value: value.value,
        calculate: value.calculate,
        listchange: value.listchange,
        panel: value.panel,
      });
      const resptwo = await axiosApi.cabinet.get(`/api/v2/wcalc`);
      return resptwo.data;
    }
  },
  getCompany: async () => {
    const resp = await axiosApi.cabinet.get(`/api/v2/company/`);
    return resp.data;
  },
  setCompany: async (value: any) => {
    const respset = await axiosApi.cabinet.post(`/api/v2/company/`, {
      ...value,
    });
    const resp = await axiosApi.cabinet.get(`/api/v2/company/`);
    return resp.data;
  },
  getAccounts: async () => {
    const resp = await axiosApi.cabinet.get(`/api/v2/account/`);
    return resp.data;
  },
  setPassword: async (value: any) => {
    try {
      const resp = await axiosApi.cabinet.post(
        `/api/v2/auth/users/set_password/`,
        {
          new_password: value.new_password,
          current_password: value.current_password,
        }
      );
      return resp.status;
    } catch (error: any) {
      return error.code;
    }
  },
  changeRole: async (value: IRole) => {
    console.log(value);
    const respone = await axiosApi.cabinet.get(`/api/v2/role/`);
    const temp = await respone.data.findIndex(
      (el: any) => el.user === value.user
    );
    if (temp !== -1) {
      const resp = await axiosApi.cabinet.patch(`/api/v2/role/${temp + 1}/`, {
        role: value.role,
        from_company: value.from_company,
      });
    } else {
      const resp = await axiosApi.cabinet.post(`/api/v2/role/`, {
        user: value.user,
        role: value.role,
        from_company: Number(value.from_company),
      });
    }
    const resptwo = await axiosApi.cabinet.get(`/api/v2/account/`);
    return resptwo.data;
  },
};
