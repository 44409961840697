import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getCalcFromOffers } from "../../store/ThunkCreator";
import { ReactNode } from "react";
import { IOffers } from "../../interfaces";
import { OfferLayoutFull, RowResultOfferLayoutFull } from "./OfferLayoutFull";

function RowResult(props: any) {
  let navigate = useNavigate();
  const { row } = props;

  const dispatch = useAppDispatch();

  const handlerlink = () => {
    dispatch(getCalcFromOffers(row.id));
    navigate(`/panel/${row.id}`, {
      state: { percent: row.discount, comment: row.comment },
    });
  };
  return <RowResultOfferLayoutFull handlerlink={handlerlink} row={row} />;
}

export function OffersListAll() {
  const { value: offers } = useAppSelector((state) => state.OffersFullReducer);
  const listOffer: ReactNode[] = offers
    .filter((e) => e.summ !== "0")
    .map((row: IOffers) => {
      return row ? <RowResult key={`result${row.id}`} row={row} /> : <></>;
    });
  return <OfferLayoutFull listOffer={listOffer} />;
}
