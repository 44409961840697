import { createSlice } from "@reduxjs/toolkit";
import { DataState, ICompany } from "../../interfaces";
import { getCompany, setCompany } from "../ThunkCreator";

// const mokUser: ICompany = [{
//   company: "",
//   inn: 0,
//   address: "",
// }];

export const initialState: DataState<ICompany[]> = {
  // value: [],
  value: [],
  loading: false,
  error: "",
};

export const CompanySlice = createSlice({
  name: "getCompany",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(getCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? "";
    });
    builder.addCase(setCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(setCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(setCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? "";
    });
  },
});

export default CompanySlice.reducer;
// getProfile;
