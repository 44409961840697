import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { IChoose } from "../../../interfaces";
import { TextSecondary } from "../../../components";
import { useAppDispatch } from "../../../hooks";
import { addChoose } from "../../../store/ActionCreators";
import { Box } from "@mui/material";

function isFilter(el: any) {
  if (el.name === "" || el.closed === false) {
    return false;
  }
  return true;
}

const handler = (name: string, choose: IChoose[], dispatch: any) => {
  return (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (!newAlignment) {
      let choosecopy = choose.map((el) =>
        el.name === name ? { ...el, meaning: "не выбран" } : el
      );
      dispatch(addChoose(choosecopy));
    }
    if (newAlignment) {
      let choosecopy = choose.map((el) =>
        el.name === name ? { ...el, meaning: newAlignment.toString() } : el
      );
      dispatch(addChoose(choosecopy));
    }
  };
};

export const GroupButton = (props: any) => {
  const { data, name, choose, exceptcalc, excep } = props;
  const dispatch = useAppDispatch();
  const handleChange = handler(name, choose, dispatch);
  let chooseId = choose.find((el: any) => el.name === name);
  let datafilter = data.filter((el: IChoose) => isFilter(el));
  const funcex = (el: string): boolean => {
    let answer = Boolean(
      excep.find((et: any) => et.name === name && et.meaning === el)
    );
    // console.log(answer);
    return answer;
  };

  return (
    <Box
      component="div"
      sx={{
        border: "1px solid #102b65",
        borderBottom: "0px",
        paddingLeft: 1,
        paddingBottom: 1,
        margin: 0,
      }}
    >
      <TextSecondary>{name}</TextSecondary>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={chooseId.meaning}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {datafilter.map((el: any, index: number) => {
          let disabled = funcex(el.name);
          if (!disabled) {
            return (
              <ToggleButton key={index} value={el.name} disabled={false}>
                {el.name}
              </ToggleButton>
            );
          } else {
            return (
              <ToggleButton key={index} value={el.name} disabled={true}>
                {el.name}
              </ToggleButton>
            );
          }
        })}
      </ToggleButtonGroup>
    </Box>
  );
};
