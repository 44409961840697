import { Panel } from "../panel/Panel";
import { useState } from "react";
import { PreResult } from "../preresult/PreResultCard";
import { PanelLayout } from "./PanelLayout";
import { CustomizedSnackbars } from "./Alert";
import { ResultTableCalculate } from "../result";

export const Calculate = (): JSX.Element => {
  const [isResult, setIsResult] = useState<Boolean>(false);

  return (
    <>
      <CustomizedSnackbars />
      <PanelLayout
        panel={<Panel setIsResult={setIsResult} />}
        preresult={<PreResult setIsResult={setIsResult} isResult={isResult} />}
        result={<ResultTableCalculate />}
      />
    </>
  );
};
