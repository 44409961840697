import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, TextField } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAccounts,
  getCompany,
  setCompany,
  changeRole,
} from "../../../store/ThunkCreator";
import BasicSelect from "../Select/Select";
import SelectUser from "../Select/SelectUser";
import SelectCompany from "../Select/SelectCompany";

export interface IRole {
  from_company: number;
  role: number;
  user: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppointRole() {
  const { value: accounts, error } = useAppSelector(
    (state) => state.AccountsReducer
  );
  const { value: company } = useAppSelector((state) => state.CompanyReducer);
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAccounts("запрос"));
    dispatch(getCompany("запрос"));
  }, []);

  const [role, setRole] = useState<IRole>({
    from_company: 0,
    role: 0,
    user: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlerSave = () => {
    dispatch(changeRole(role));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [form, setForm] = useState({
    company: "",
    inn: "",
    address: "",
  });
  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((formProps) => ({
      ...formProps,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ mt: 1, mr: 1 }}
        onClick={handleClickOpen}
      >
        Назначить роль пользователю
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Назначить роль пользователю
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "40ch" },
            p: 2,
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            {/* <TextField
              id="outlined-name"
              label="Пользователь"
              name="company"
              value={form.company}
              onChange={handleForm}
            /> */}
            <SelectUser accounts={accounts} role={role} setRole={setRole} />
            <BasicSelect accounts={accounts} role={role} setRole={setRole} />
            <SelectCompany
              company={company}
              accounts={accounts}
              role={role}
              setRole={setRole}
            />
            {/* <TextField
              id="outlined-inn"
              label="Компания"
              name="inn"
              value={form.inn}
              onChange={handleForm}
            /> */}
            {/* <TextField
              id="outlined-address"
              label="Роль"
              name="address"
              value={form.address}
              onChange={handleForm}
            /> */}
            <Button
              // variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handlerSave}
            >
              Назначить
            </Button>
            {/* <Button
              // variant="contained"
              sx={{ mt: 2, ml: 1 }}
              // onClick={handlerSave}
            >
              Отключить пользователя
            </Button> */}
          </div>
        </Box>
        <List>
          {accounts.map((el) => {
            const value = `роль: ${el.accounts?.role || " "}, компания: ${
              el.accounts?.from_company || " "
            } тест ${el.id}`;
            return (
              <>
                <ListItem key={el.id}>
                  <ListItemText
                    key={el.id}
                    primary={el.username}
                    secondary={value}
                  />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}
