import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SelectUserProps } from "./SelectUser";

export default function SelectCompany(props: SelectUserProps) {
  const { company, role, setRole } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setRole({ ...role, from_company: Number(event.target.value) });
  };

  return (
    <FormControl sx={{ width: "240px", mt: 1, ml: 1 }}>
      <InputLabel id="demo-simple-select-label">Компания</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={role.from_company.toString()}
        label="Роль"
        onChange={handleChange}
      >
        {company &&
          company.map((el) => {
            return <MenuItem value={el.id.toString()}>{el.company}</MenuItem>;
          })}
      </Select>
    </FormControl>
  );
}
