import { TableBody as MUITableBody, TableCell, TableRow } from "@mui/material";
import { getComparator, stableSort } from "./sortby";
import { TableBodyProps } from "../../interfaces";
import { FormDialog } from "./FormDialog";
import { Row } from "./TabRow";
import { useAppSelector } from "../../hooks/redux";

export function TableBody(props: TableBodyProps): JSX.Element {
  const { order, orderBy } = props;
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);

  return (
    <MUITableBody>
      {stableSort<any>(calculate, getComparator(order, orderBy)).map(
        (row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return <Row key={row.id} row={row} labelId={labelId} />;
        }
      )}

      <TableRow>
        <TableCell />
        <TableCell>
          <FormDialog />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </MUITableBody>
  );
}
