import { createSlice } from "@reduxjs/toolkit";
import { DataState, INewOffer } from "../../interfaces";
import { createOffer, doubleOffer, getOffer } from "../ThunkCreator";

export const initialState: DataState<INewOffer | null> = {
  value: null,
  loading: false,
  error: "",
};

export const NewOfferSlice = createSlice({
  name: "newoffers",
  initialState,
  reducers: {
    deleteNewOffer(state) {
      state.value = null;
    },

    addComment(state, action) {
      if (state.value !== null) {
        state.value = { ...state.value, comment: action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(createOffer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(getOffer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(doubleOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(doubleOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.newoffer;
    });
    builder.addCase(doubleOffer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default NewOfferSlice.reducer;
