import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loginAccount } from "../../store/ThunkCreator";
import "./Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { AccountCircle, Check } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";

interface FormData {
  username: string;
  password: string;
}

export const Login = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const { value: user, error } = useAppSelector((state) => state.UserReducer);
  const [errors, setErrors] = useState<FormData>({
    username: "",
    password: "",
  });
  const [exsist, setExsist] = useState(true);
  let from = location.state?.from?.pathname || "/";
  // let from = "/";
  const [form, setForm] = useState<FormData>({
    username: "",
    password: "",
  });

  const [toggleClass, setToggleClass] = useState(false);

  const validate = () => {
    let newErrors: FormData = { username: "", password: "" };

    if (!form.username) {
      newErrors.username = "Заполните логин";
    }

    if (!form.password) {
      newErrors.password = "Укажите пароль";
    }

    setErrors(newErrors);
    console.log(errors);

    return !Object.values(newErrors).some((error) => error.length > 0);
  };

  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    if (validate()) {
      console.log("Форма валидна");
    } else {
      console.log("Форма не валидна");
    }
    setForm((formProps) => ({
      ...formProps,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const annoyingSubmitButton = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setExsist(true);
    if (validate()) {
      dispatch(loginAccount(form));
    }
    if (!user.company && !errors.username)
      setTimeout(() => setExsist(false), 500);
  };
  useEffect(() => {
    // console.log(error.toString());
    if (!!user.username) {
      setTimeout(() => navigate(from, { replace: true }), 200);
    }
  }, [user]);
  useEffect(() => {
    // console.log(error.toString());
    if (error) {
      setErrors({ username: "Неверный логин или пароль", password: " " });
    }
  }, [error]);
  const hand = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      if (form.password.length < 5) {
        setToggleClass((prevState) => !prevState);
      }
      dispatch(loginAccount(form));
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ mb: 10 }}>
        <Box
          className="auth-header"
          component="h1"
          sx={{
            width: "100%",
            maxWidth: 600,
            margin: "auto",
            mb: 2,
          }}
        >
          Авторизация
        </Box>

        <Box
          className="reg-wrapper"
          sx={{
            maxWidth: 600,
            width: "100%",
            mr: "auto",
            ml: "auto",
            mb: 2,
          }}
        >
          <Box
            sx={{
              "& .MuiTextField-root": { mb: 1, maxWidth: 600, width: "100%" },
            }}
            display="flex"
            flexDirection={"column"}
          >
            <Box sx={{ mt: 1 }}>
              <form onSubmit={annoyingSubmitButton}>
                <TextField
                  fullWidth
                  id="username"
                  label="Login"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle sx={{ fill: "rgb(63 68 78)" }} />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  name="username"
                  autoFocus
                  autoComplete="username"
                  value={form.username}
                  onChange={handleForm}
                  error={!!errors.username}
                  helperText={errors.username}
                  onKeyDown={hand}
                  tabIndex={-1}
                  required
                />
                <TextField
                  sx={{ width: 1 }}
                  id="password"
                  label="Password"
                  type="password"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ fill: "rgb(63 68 78)" }} />
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  autoComplete="password"
                  value={form.password}
                  onChange={handleForm}
                  onKeyDown={hand}
                  error={!!errors.password}
                  helperText={errors.password}
                  tabIndex={1}
                />
                <div>
                  {form.password.length < 5 ? (
                    <p className="warning-message">
                      Пароль должен быть длинее 6 символов
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {!exsist && (
                    <p className="warning-message">Ожидайте подтверждения</p>
                  )}
                </div>
                {/* <button
                tabIndex={-1}
                className={`submit-button ${
                  form.password.length > 5 ? "button-success" : ""
                }`}
                onClick={annoyingSubmitButton}
              >
                Вход
              </button> */}
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    mb: 1,
                    "&:hover": {
                      background: "#FFF",
                      color: "#F7941E",
                    },
                  }}
                  // onClick={annoyingSubmitButton}
                >
                  <Check /> Авторизация
                </Button>
              </form>
            </Box>

            <Grid container>
              <Grid item xs>
                <Link href="/demo" variant="body2">
                  Демонстрационная версия
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"У вас нет учетной записи? Зарегистрироваться"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
