import { Routes, Route } from "react-router-dom";
import { OfferMain } from "../module";

export const OffersPage = () => {
  return (
    <Routes>
      <Route path="/" element={<OfferMain />} />
      <Route path={`/:calcId`} element={<OfferMain />} />
    </Routes>
  );
};
