import { createSlice } from "@reduxjs/toolkit";
import { DataState, IChoose, IObjects } from "../../interfaces";
import {
  achiveProject,
  createObject,
  getProject,
  searchProject,
} from "../ThunkCreator";

export const initialState: DataState<IObjects[]> = {
  value: [],
  loading: false,
  error: "",
};

export const ObjectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    // addChoose(state, action) {
    //   state.value = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(createObject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createObject.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(createObject.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(getProject.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(achiveProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(achiveProject.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(achiveProject.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchProject.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(searchProject.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ObjectsSlice.reducer;
