import { CardContent, Button, Grid, TextField } from "@mui/material";
import { TextSecondary } from "../../../components";
import { BoxResult } from "../calculate/PanelLayout";
import { LayoutPreResultProps } from "./PreResultProps";

export const PreResultLayout = (props: LayoutPreResultProps) => {
  const { isResult, isFixed, name, price, addNode, count, changeHandler } =
    props;
  if (isResult) {
    return (
      <>
        <CardContent sx={{ padding: 1, pt: 0 }}>
          <TextSecondary>Результат</TextSecondary>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 2, sm: 3, md: 4 }}
            justifyContent="space-between"
          >
            <Grid item xs={8}>
              <TextSecondary>Узел этажный коллекторный</TextSecondary>
            </Grid>
            <Grid item xs={4}>
              <TextSecondary>Стоимость</TextSecondary>
            </Grid>
            <Grid item xs={8}>
              <TextSecondary sx={{ fontSize: 14, fontWeight: "bold" }}>
                {name}
              </TextSecondary>
            </Grid>
            <Grid item xs={4}>
              <TextSecondary sx={{ fontSize: 14, fontWeight: "bold" }}>
                {price}
              </TextSecondary>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="outlined-number"
                label="Укажите количество"
                value={count}
                type="number"
                onChange={changeHandler}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                color="success"
                onClick={addNode}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  } else if (!isFixed) {
    return <BoxResult />;
  } else {
    return <></>;
  }
};
