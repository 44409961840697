import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IPreCatalog } from "../../interfaces";
import { columns } from "./createData";
import { RowCatalog as Row } from "./TabRow";
import { FormCreate } from "./FormCreateEquip";
import { Layout } from "../Layout/layout";
import { getPreCatalogM } from "../../store/ThunkCreator";
import { useDebounce } from "../../hooks";

export function StickyHeadTable() {
  const { precatalog: value } = useAppSelector(
    (state) => state.PreCatalogReducer
  );
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [datatemp, setDatatemp] = useState<IPreCatalog[]>([]);
  const [search, setSearch] = useState("");
  const debounced = useDebounce<string>(search, 1000);
  useEffect(() => {
    dispatch(getPreCatalogM("1"));
  }, []);
  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }
  const funcsearch = (el: IPreCatalog, deb: string) => {
    if (el.name.includes(deb)) {
      return true;
    } else if (typeof el.type_auto === "string" && el.type_auto.includes(deb)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // dispatch(getPreCatalogM(""));
    let clone = value.filter((el) => funcsearch(el, debounced));
    setDatatemp(clone);
  }, [value, debounced]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Layout>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div style={{ display: "flex" }}>
          <FormCreate />
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled1"
              label="Поиск"
              value={search}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(getPreCatalogM("1"));
                setSearch(event.target.value);
              }}
            />
          </Box>
        </div>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {datatemp
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IPreCatalog, index: number) => (
                  <Row
                    key={row.name}
                    row={row}
                    data={datatemp}
                    index={page * rowsPerPage + index}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={datatemp.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
}
