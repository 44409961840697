import { createSlice } from "@reduxjs/toolkit";
import { DataState, INewOffer, resultCalcData } from "../../interfaces";

export const initialState: DataState<resultCalcData[]> = {
  value: [],
  loading: false,
  error: "",
};

export const DublicatCalcSlice = createSlice({
  name: "dublicatcalc",
  initialState,
  reducers: {
    dublCalc(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default DublicatCalcSlice.reducer;
