import { useEffect, useState, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { TextField, Autocomplete, Button } from "@mui/material";
import { useDebounce } from "../../hooks/debounce";
import { useNavigate } from "react-router-dom";
import { IEquipment, ITerm } from "../../interfaces";
import { addChange } from "../../store/ActionCreators";
import { FormDialogChoose } from "./FormDialogChoose";
import { useSaveCalc } from "./useSaveCalc";

interface ComBoxProps {
  elname: string;
  meaning: string[];
  term: ITerm[];
  handler(elname: string): (event: any) => void;
  handlerChange(elname: string): (event: any) => void;
}

const ComboBox = (props: ComBoxProps) => {
  const { elname, meaning, handler, handlerChange, term } = props;
  const arrmeaning = meaning.map((el: string): string => {
    // return { label: el };
    return el;
  });
  const handlerone = handler(elname);
  const handlertwo = handlerChange(elname);

  const temp = term.find((el) => el.name === elname)?.meaning ?? "";
  // const [value, setValue] = useState<string | null>(
  //   temp === undefined ? null : temp
  // );
  // const [invalue, setInValue] = useState<string>(
  //   temp === undefined ? "" : temp
  // );
  return (
    <Autocomplete
      disablePortal
      freeSolo
      value={temp === undefined ? "" : temp}
      onChange={(event: any, newValue: string | null) => {
        handlertwo(newValue);
      }}
      id={`combo-box-demo${elname}`}
      options={arrmeaning}
      getOptionLabel={(option: any) => option}
      sx={{ width: 500 }}
      onInputChange={handlerone}
      inputValue={temp === undefined ? "" : temp}
      renderInput={(params) => <TextField {...params} label={elname} />}
    />
  );
};

interface CalcIdProps {
  calcId?: string;
  accounts: IEquipment;
}

export const SettingChoose = (props: CalcIdProps) => {
  const { calcId, accounts } = props;
  const {
    position,
    name,
    listChange,
    handleChange,
    handlerChangeTwo,
    term,
    savecalc,
  } = useSaveCalc(calcId ?? undefined, accounts);
  return (
    <>
      {position}
      <hr />
      {name}
      <hr />
      {listChange.map((el, index) => (
        <ComboBox
          key={index}
          // term={term}
          elname={el.name}
          meaning={el.meaning}
          handler={handleChange}
          handlerChange={handlerChangeTwo}
          term={term}
        />
      ))}
      <hr />
      <FormDialogChoose />
      <Button onClick={savecalc}>Сохранить</Button>
    </>
  );
};
