import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCompany, setCompany } from "../../../store/ThunkCreator";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePartner() {
  const { value: company, error } = useAppSelector(
    (state) => state.CompanyReducer
  );
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCompany("запрос"));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlerSave = () => {
    dispatch(setCompany(form));
    console.log(error);
    console.log(form);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [form, setForm] = useState({
    company: "",
    inn: "",
    address: "",
    discount: "",
  });
  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "discount") {
      if (Number(e.target.value) > -0.01 && Number(e.target.value) < 50.1) {
        setForm((formProps) => ({
          ...formProps,
          [e.target.name]: e.target.value,
        }));
      }
    } else {
      setForm((formProps) => ({
        ...formProps,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ mt: 1, mr: 1 }}
        onClick={handleClickOpen}
      >
        Создать контрагента
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Создание контрагента
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "40ch" },
            p: 2,
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-name"
              label="Наименование"
              name="company"
              value={form.company}
              onChange={handleForm}
            />
            <TextField
              id="outlined-inn"
              label="ИНН"
              name="inn"
              value={form.inn}
              onChange={handleForm}
            />
            <TextField
              id="outlined-address"
              label="Адресс"
              name="address"
              value={form.address}
              onChange={handleForm}
            />
            <FormControl variant="outlined" sx={{ mt: 1 }}>
              <OutlinedInput
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                name="discount"
                value={form.discount}
                onChange={handleForm}
                type="number"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
            <Button
              // variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handlerSave}
            >
              Создать контрагента
            </Button>
            <Button
              // variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handlerSave}
            >
              Заменить
            </Button>
          </div>
        </Box>
        <List>
          {company.map((el) => (
            <>
              <ListItem button>
                <ListItemText
                  primary={el.company}
                  secondary={`ИНН ${el.inn}, Адресс: ${
                    el.address
                  }, Скидка на коллектор отопления: ${el.discount.toString(2)}`}
                />
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </Dialog>
    </div>
  );
}
