import { api } from "../api";
import { IOffersPatch } from "../interfaces";

export const useDownloadPDF = (
  forpdf: string | undefined,
  total: string,
  percent: string,
  summ: string
) => {
  return () => {
    let value: IOffersPatch = {
      id: forpdf,
      summ: total,
      discount: percent,
      price: summ,
    };
    (async () => {
      // await api.patchOfferSimple(value);
      // await api.savePDF(forpdf);
      await api.getPDF(forpdf);
    })();
  };
};
