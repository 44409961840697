import { Card } from "@mui/material";
import { LayoutProps } from "./layoutProps";

export function LayoutCards(props: LayoutProps) {
  const { children } = props;
  return (
    <>
      <Card
        hidden={false ? true : false}
        raised
        sx={{ minWidth: 275, minHeight: 800, mb: 1 }}
      >
        {children}
      </Card>
    </>
  );
}
