import { createSlice } from "@reduxjs/toolkit";
import { DataState, IChoose, IOffers, OffersData } from "../../interfaces";
import { achiveOffer, offerFromBuild } from "../ThunkCreator";

export const initialState: DataState<IOffers[]> = {
  value: [],
  loading: false,
  error: "",
};

export const OffersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    // addChoose(state, action) {
    //   state.value = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(createOffer.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(createOffer.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.value = action.payload;
    // });
    // builder.addCase(createOffer.rejected, (state) => {
    //   state.loading = false;
    // });
    builder.addCase(offerFromBuild.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(offerFromBuild.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.offers;
    });
    builder.addCase(offerFromBuild.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(achiveOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(achiveOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.offers;
    });
    builder.addCase(achiveOffer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default OffersSlice.reducer;
