import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

export default function ButtonPDF(props: {
  isFixed: boolean;
  handlerpdf: () => void;
}) {
  const { isFixed, handlerpdf } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    !isFixed && setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    !isFixed && setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        variant="contained"
        onClick={!isFixed ? () => {} : handlerpdf}
        fullWidth
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        Выгрузить КП
      </Button>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          Сохраните расчет перед выгрузкой в PDF
        </Typography>
      </Popover>
    </div>
  );
}
