import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { CountPumpType, DataArray } from "./PumpCalculate";

interface InputAdornmentsProps {
  xy: { x: string | undefined; y: string | undefined };
  handlerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlerCount: (e: SelectChangeEvent<CountPumpType>) => void;
  countPump: CountPumpType;
  dataFirst: DataArray[];
  dataSecond: DataArray[];
  dataThreed: DataArray[];
}
interface FormData {
  firstSelect: string;
  secondSelect: string;
  threedSelect: string;
}

export function InputAdornments({
  xy,
  handlerChange,
  countPump,
  handlerCount,
  dataFirst,
  dataSecond,
  dataThreed,
}: InputAdornmentsProps) {
  const [formData, setFormData] = useState<FormData>({
    firstSelect: "",
    secondSelect: "",
    threedSelect: "",
  });
  const [firstOptions, setFirstOptions] = useState<string[]>([]);
  const [secondOptions, setSecondOptions] = useState<string[]>([]);
  const [threedOptions, setThreedOptions] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Загрузка первого списка опций
    // Это может быть асинхронный запрос к API
    const loadFirstOptions = async () => {
      const options = dataFirst.map((el) => el.key); // Замените на реальный запрос
      setFirstOptions(options);
    };
    loadFirstOptions();
  }, []);

  useEffect(() => {
    const loadSecondOptions = async () => {
      let options: string[] =
        dataFirst.find((el) => el.key === formData.firstSelect)?.value ?? [];

      setSecondOptions(options);
    };
    if (formData.firstSelect) {
      loadSecondOptions();
    }
  }, [formData.firstSelect]);

  useEffect(() => {
    const loadThreedOptions = async () => {
      let options: string[] =
        dataSecond.find((el) => el.key === formData.secondSelect)?.value ?? [];

      setThreedOptions(options);
    };
    if (formData.secondSelect) {
      loadThreedOptions();
    }
  }, [formData.secondSelect]);

  const handleFirstSelectChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      firstSelect: event.target.value,
      secondSelect: "",
    });
  };

  const handleSecondSelectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, secondSelect: event.target.value });
  };
  const handleThreedSelectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, threedSelect: event.target.value });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flexStart",
        mr: 1,
      }}
    >
      <div style={{ height: "250px" }}>
        <div>
          <TextField
            label="Укажите расход"
            size="small"
            type="number"
            id="outlined-start-adornment"
            sx={{ m: 1, width: "50ch" }}
            name="x"
            value={xy.x}
            onChange={handlerChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">м&#179;/ч</InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <TextField
            size="small"
            label="Укажите напор"
            type="number"
            id="outlinart-adornment"
            sx={{ m: 1, width: "50ch" }}
            name="y"
            value={xy.y}
            onChange={handlerChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">м.в.ст.</InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <FormControl sx={{ minWidth: "150px", ml: 1, mt: 1 }}>
            <InputLabel id="demo-simple-select-label2">
              Кол-во рабочих
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={countPump}
              label="Тип"
              onChange={handlerCount}
            >
              <MenuItem value={0}>{"Кол-во рабочих"}</MenuItem>
              <MenuItem value={1}>{1}</MenuItem>
              <MenuItem value={2}>{2}</MenuItem>
              <MenuItem value={3}>{3}</MenuItem>
              <MenuItem value={4}>{4}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: "200px", ml: 1, mt: 1 }}>
            <InputLabel id="demo-simple-select-label1">
              Семейство насосов
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label1"
              id="demo-simple-select1"
              value={formData.firstSelect}
              label="Семейство насосов"
              onChange={handleFirstSelectChange}
            >
              <MenuItem value="">Выберите опцию</MenuItem>
              {firstOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.firstSelect && (
            <FormControl sx={{ minWidth: "150px", ml: 1, mt: 1 }}>
              <InputLabel id="demo-simple-select-label">Тип</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.secondSelect}
                label="Тип"
                onChange={handleSecondSelectChange}
              >
                {secondOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {formData.secondSelect && (
            <FormControl sx={{ minWidth: "515px", ml: 1, mt: 1 }}>
              <InputLabel id="demo-simple-select-label2">
                Наименование
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                value={formData.threedSelect}
                label="Тип"
                onChange={handleThreedSelectChange}
              >
                {threedOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      {/* <Button variant="contained" onClick={() => {}} fullWidth>
        Рассчитать
      </Button> */}
    </Box>
  );
}
