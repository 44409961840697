import { FormControl, NativeSelect, InputLabel } from "@mui/material";
import { ChangeEvent } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { addPanel } from "../../store/ActionCreators";

interface NativeSelectProps {
  type: string;
  types: any;
  ident: string;
  typechoose: string;
  description: Boolean;
}

export function SelectDefault(props: NativeSelectProps) {
  // const { types } = useAppSelector((state) => state.typeEquipmentReducer);
  const { value } = useAppSelector((state) => state.PanelReducer);
  const dispatch = useAppDispatch();
  const { type, description, types, ident, typechoose } = props;
  const changeHandlerType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let valPanel = value.map((el) => {
      if (el.id === ident && typechoose === "default") {
        return { ...el, default: event.target.value };
      } else if (el.id === ident && typechoose === "position") {
        return { ...el, position: event.target.value };
      } else {
        return { ...el };
      }
    });
    dispatch(addPanel(valPanel));
    console.log("Нажал: ", valPanel);
  };
  return (
    <FormControl variant="outlined" fullWidth>
      {description ? (
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Выбери тип оборудования
        </InputLabel>
      ) : (
        <></>
      )}
      <NativeSelect
        disableUnderline
        variant="outlined"
        defaultValue={type}
        onChange={changeHandlerType}
        inputProps={{
          name: "Выберите тип оборудования",
          id: "uncontrolled-native",
        }}
      >
        {types.map((rowtype: string) => (
          <option key={rowtype} value={rowtype}>
            {rowtype}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
