import { combineReducers, configureStore } from "@reduxjs/toolkit";
import PanelReducer from "./slices/PanelSlice";
import PanelUserReducer from "./slices/PanelUserSlice";
import PreResultReducer from "./slices/PreResult";
import ResultReducer from "./slices/ResultSlice";
import ChooseReducer from "./slices/ChooseSlice";
import ExceptCalcReducer from "./slices/ExceptCalcSlice";
import UserReducer from "./slices/UserSlice";
import ObjectsReducer from "./slices/ObjectsSlice";
import OffersReducer from "./slices/OffersSlice";
import NewOfferReducer from "./slices/NewOfferSlice";
import DublicatCalcReducer from "./slices/DublicatCalcSilce";
import OffersFullReducer from "./slices/OffersFullSlice";
import typeEquipmentReducer from "./slices/TypeEquipmentSlice";
import catalogReducer from "./slices/CatalogSlice";
import ChangeReducer from "./slices/ChangeSlice";
import PreCatalogReducer from "./slices/PreCatalogSlice";
import ManageCalcReducer from "./slices/ManageCalcSlice";
import CompanyReducer from "./slices/CompanySlice";
import AccountsReducer from "./slices/ListAccountsSlice";
import ChangeResultReducer from "./slices/ChangeResultSlice";

const rootReducer = combineReducers({
  PanelReducer,
  PanelUserReducer,
  PreResultReducer,
  ChooseReducer,
  ResultReducer,
  ExceptCalcReducer,
  UserReducer,
  ObjectsReducer,
  OffersReducer,
  NewOfferReducer,
  DublicatCalcReducer,
  OffersFullReducer,
  typeEquipmentReducer,
  catalogReducer,
  ChangeReducer,
  PreCatalogReducer,
  ManageCalcReducer,
  CompanyReducer,
  AccountsReducer,
  ChangeResultReducer,
});

export function setupStore() {
  return configureStore({
    reducer: rootReducer,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
