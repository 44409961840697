import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { resultCalcData } from "../interfaces";
import {
  deleteNewOffer,
  //  dublCalc
} from "../store/ActionCreators";
import { getCalcFromOffers } from "../store/ThunkCreator";
import { useEmptyCalc } from "./navigate";

export const useUpdateResult = (): resultCalcData[] => {
  const isFixed = useEmptyCalc();
  const { value: offer } = useAppSelector((state) => state.NewOfferReducer);
  const { value: rows } = useAppSelector((state) => state.ResultReducer);
  const dispatch = useAppDispatch();
  const { calcId } = useParams();
  useEffect(() => {
    if (isFixed && calcId) {
      dispatch(getCalcFromOffers(calcId));
    } else if (offer) {
      dispatch(getCalcFromOffers(offer.id.toString()));
    }
  }, [offer]);
  return rows;
};
