import { Column } from "../../interfaces";
export const columns: readonly Column[] = [
  { id: "name", label: "Наименование", minWidth: 400 },
  {
    id: "name_handler",
    label: "Заменить наименование",
    minWidth: 50,
    handler: true,
  },
  {
    id: "type_auto",
    label: "Тип оборудования",
    minWidth: 150,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "type_manual",
    label: "Тип оборудования (замена)",
    minWidth: 150,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "type_handler",
    label: "Установить тип вручную",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
    handler: true,
  },
  {
    id: "price_auto",
    label: "Цена\u00a0из\u00a01С",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "price_manual",
    label: "Цена(замена)",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "price_handler",
    label: "Установить цену вручную",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
    handler: true,
  },
  { id: "name_spec", label: "Наименование спецификации", minWidth: 300 },
];
