import ReactDOM from "react-dom/client";
import { App } from "./App";
import { Provider } from "react-redux";
import { setupStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import DINNextW1G from "../public/654font.ttf";
import "./index.css";

const store = setupStore();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#112B66",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //   main: "#E0C2FF",
    //   light: "#F5EBFF",
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: "#47008F",
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: "linear-gradient(45deg, #102b65, #005d90)",
              "&:hover": {
                background: "#202020",
              },
              // color: "#fff",
            }),
        }),
      },
    },
  },
  typography: {
    fontFamily: ["DIN Next W1G"].join(","),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
