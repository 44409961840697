import { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { offerFromBuild, offersAll } from "../store/ThunkCreator";
import { useAppDispatch } from "./redux";

export const useUpdateOffer = () => {
  const { calcId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (calcId) dispatch(offerFromBuild(calcId));
  }, [calcId]);
};

export const useUpdateOfferAll = () => {
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    dispatch(offersAll("запрос"));
  }, []);
};
