import { createSlice } from "@reduxjs/toolkit";
import { DataState, resultCalcData } from "../../interfaces";
import {
  changeCalc,
  changeCountCalc,
  createCalc,
  deleteCalcFromOffers,
  doubleOffer,
  getCalcFromOffers,
} from "../ThunkCreator";
// import { mokResult } from "./mok";

const initialState: DataState<resultCalcData[] | []> = {
  value: [],
  loading: false,
  error: "",
};

export const ResultSlice = createSlice({
  name: "calcOffer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCalcFromOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCalcFromOffers.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          name: el.name,
          code: "el", //ЗАПЛАТКА
          count: el.count,
          size: el.price,
          density: el.total_price,
          specification: el.positions,
          choose: el.choose,
        };
      });
    });
    builder.addCase(getCalcFromOffers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          name: el.name,
          code: "el", //ЗАПЛАТКА
          count: el.count,
          size: el.price,
          density: el.total_price,
          specification: el.positions,
          choose: el.choose,
        };
      });
    });
    builder.addCase(createCalc.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          name: el.name,
          code: "el", //ЗАПЛАТКА
          count: el.count,
          size: el.price,
          density: el.total_price,
          specification: el.positions,
          choose: el.choose,
        };
      });
    });
    builder.addCase(changeCalc.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(changeCountCalc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCountCalc.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          name: el.name,
          code: "el", //ЗАПЛАТКА
          count: el.count,
          size: el.price,
          density: el.total_price,
          specification: el.positions,
          choose: el.choose,
        };
      });
    });
    builder.addCase(changeCountCalc.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(doubleOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(doubleOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.calculate.map((el: any) => {
        return {
          idpos: el.id,
          name: el.name,
          code: "el", //ЗАПЛАТКА
          count: el.count,
          size: el.price,
          density: el.total_price,
          specification: el.positions,
          choose: el.choose,
        };
      });
    });
    builder.addCase(doubleOffer.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCalcFromOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCalcFromOffers.fulfilled, (state, action) => {
      state.loading = false;
      state.value = state.value.filter((el) => el.idpos !== action.payload);
    });
    builder.addCase(deleteCalcFromOffers.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ResultSlice.reducer;
