import { useAppDispatch, useAppSelector } from "../hooks";
import { nanoid } from "nanoid";
import { ChangeEvent, useEffect, useState } from "react";
import {
  changeCalc,
  createCalc,
  getCalcFromOffers,
} from "../store/ThunkCreator";

type AddListProps = [
  string,
  string,
  string,
  () => void,
  (event: ChangeEvent<HTMLInputElement>) => void
];

export const useAddList = (
  setIsResult: (isResult: boolean) => void
): AddListProps => {
  const [count, setCount] = useState("1");
  const { value: offer } = useAppSelector((state) => state.NewOfferReducer);
  const dispatch = useAppDispatch();
  const { value: preRes } = useAppSelector((state) => state.PreResultReducer);
  const { value: changeicon } = useAppSelector(
    (state) => state.ChangeResultReducer
  );
  const handlerSave = () => {
    const temp = changeicon.find((el: any) => el.value === false)?.idpos;
    if (temp && offer) {
      //TODO: PUT запрос заменить существующий расчет
      console.log(temp);
      let valtemp = {
        idpos: temp,
        name: preRes.name,
        code: nanoid(),
        count: +count,
        size: preRes.price,
        specification: preRes.specification,
        density: preRes.price * +count,
        choose: preRes.choose,
      };
      dispatch(
        changeCalc({
          idpos: valtemp.idpos,
          name: valtemp.name,
          price: valtemp.size,
          count: valtemp.count,
          totalPrice: valtemp.density,
          offers: offer.id,
          positions: preRes.specification,
          choose: valtemp.choose,
        })
      );
      setIsResult(false);
    } else if (offer) {
      let valtemp = {
        name: preRes.name,
        code: nanoid(),
        count: +count,
        size: preRes.price,
        specification: preRes.specification,
        density: preRes.price * +count,
        choose: preRes.choose,
      };
      dispatch(
        createCalc({
          name: valtemp.name,
          price: valtemp.size,
          count: valtemp.count,
          totalPrice: valtemp.density,
          offers: offer.id,
          positions: preRes.specification,
          choose: valtemp.choose,
        })
      );
      setIsResult(false);
    }
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value);
  };
  return [
    count,
    preRes.name,
    preRes.price.toFixed(2).toString(),
    handlerSave,
    changeHandler,
  ];
};
