import {
  TableBody as MUITableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { NavLink as Link } from "react-router-dom";
import { Switch } from "@mui/material";
import { ICalculate } from "../../interfaces";
import { createheadtyperowsProps, useHeadTable } from "./useHeadTable";
import { ReactNode, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { addCalculate } from "../../store/ActionCreators";
import { useNavigate } from "react-router-dom";

interface CollapseTableProps {
  row: ICalculate;
  headtypeR: createheadtyperowsProps[];
}

function CustomSwitch(props: any) {
  const { terms, row } = props;
  const checkterms = (terms: any) => {
    if (
      terms.find((el: any) => el.name === "Использование")?.meaning === "Выбран"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [checked, setChecked] = useState(checkterms(terms));
  const dispatch = useAppDispatch();
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let choose: string;
    if (event.target.checked) {
      choose = "Выбран";
    } else {
      choose = "Не выбран";
    }
    let valuetem = calculate.map((el) => {
      if (el.id === row.idCalc) {
        let temp = el.equipment.map((eq) => {
          if (eq.id === row.id) {
            return {
              ...row,
              terms: row.terms.map((ter: any) => {
                if (ter.name === "Использование") {
                  return { ...ter, meaning: choose };
                } else {
                  return { ...ter };
                }
              }),
            };
          } else {
            return eq;
          }
        });
        return { ...el, equipment: temp };
      } else {
        return Object.assign({}, el);
      }
    });

    console.log("row: ", row);
    dispatch(addCalculate(valuetem));
    setChecked(event.target.checked);
  };

  return <Switch checked={checked} onChange={handleChange}></Switch>;
}

type ICallback = (his: any, meaning: string) => string;
const proxyTermsVars = (callback: ICallback, el: string) => (his: any) => {
  if (his.name === el) {
    return callback(his, his.meaning);
  } else {
    return callback(his, "");
  }
};

function CollapseTable(props: CollapseTableProps) {
  const { row, headtypeR } = props;
  const [el, eltwo, ...temp] = headtypeR;
  let navigate = useNavigate();
  let comp = { component: Link };
  return (
    <>
      {row.equipment.map((historyRow: any) => (
        <TableRow key={historyRow.name} hover>
          <TableCell
            key={`cell${historyRow.id}`}
            onClick={() => {
              navigate(`${historyRow.id}`);
            }}
            style={{ cursor: "pointer" }}
          >
            {historyRow["name"]}
          </TableCell>

          <TableCell>
            {/* <Switch key={`switch${historyRow.name}`} /> */}
            <CustomSwitch
              terms={historyRow.terms}
              row={historyRow}
            ></CustomSwitch>
          </TableCell>
          {temp.map((el) => (
            <TableCell>
              {/* {historyRow.terms.map((his: any) => {
                if (his.name === el.name) {
                  return his.meaning;
                } else {
                  return "";
                }
              })} */}
              {historyRow.terms.map(
                proxyTermsVars((his, meaning) => meaning, el.name)
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}

export function CollapseRow(props: { row: ICalculate }) {
  const { row } = props;
  const headtypeR = useHeadTable(row);
  return (
    <>
      <TableHead>
        <TableRow>
          {headtypeR.map((headrow: createheadtyperowsProps, index) => (
            <TableCell key={headrow.id} align={headrow.align}>
              {headrow.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <MUITableBody>
        <CollapseTable row={row} headtypeR={headtypeR} />
      </MUITableBody>
    </>
  );
}
