import { Box, Fab, IconButton, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useParams } from "react-router-dom";
import { addComment } from "../../../../store/ActionCreators";
import { api } from "../../../../api";

export const Comments = () => {
  const { value: newoffer } = useAppSelector((state) => state.NewOfferReducer);
  const { value: offers } = useAppSelector((state) => state.OffersReducer);
  const { calcId } = useParams();
  const location = useLocation();
  const [value, setValue] = useState("");
  const [comment, setComment] = useState(location?.state?.comment ?? "");
  const [edit, setEdit] = useState(false);
  const dispatch = useAppDispatch();
  const handlerPer = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };
  const handlerEdit = () => {
    if (calcId && edit) {
      let val = { id: Number(calcId), comment: comment };
      api.patchComment(val);
      setEdit(!edit);
    } else if (newoffer && calcId === undefined && edit) {
      let val = { id: newoffer.id, comment: comment };
      api.patchComment(val);
      setEdit(!edit);
    } else {
      setEdit(!edit);
    }
  };
  // useEffect(() => {
  //   setValue(newoffer?.comment ?? "");
  // }, []);
  return (
    <Box sx={{ display: "flex" }}>
      {edit ? (
        <TextField
          id="filled-multiline-static"
          label="Комментарии к ТКП"
          multiline
          fullWidth
          rows={5}
          // value={newoffer?.comment}
          value={comment}
          onChange={handlerPer}
          variant="filled"
        />
      ) : (
        // <Typography sx={{ flexGrow: 1 }}>{newoffer?.comment}</Typography>
        <Typography sx={{ flexGrow: 1 }}>{comment}</Typography>
      )}
      {/* <IconButton
        color="success"
        sx={{
          p: "10px",

          justifyContent: "center",
          width: 100,
          mr: 2,
        }}
        aria-label="directions"
        size="large"
      >
        <DirectionsIcon fontSize="large" />
      </IconButton> */}
      <Box>
        {edit ? (
          <Fab
            color="success"
            aria-label="edit"
            sx={{ m: 2 }}
            onClick={handlerEdit}
          >
            <ArrowForwardIosIcon />
          </Fab>
        ) : (
          <Fab
            color="secondary"
            aria-label="edit"
            size="large"
            sx={{ m: 2 }}
            onClick={handlerEdit}
          >
            <EditIcon />
          </Fab>
        )}
      </Box>
    </Box>
  );
};
// https://mui.com/material-ui/react-floating-action-button/
