import { Switch } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { addPanel } from "../../store/ActionCreators";

export const CustomSwitch = (props: any) => {
  const { value } = useAppSelector((state) => state.PanelReducer);
  const dispatch = useAppDispatch();
  const { closed, typechoose, ident } = props;
  const [open, setOpen] = useState(closed);
  const changeHandlerType = (event: React.ChangeEvent<HTMLInputElement>) => {
    let valPanel = value.map((el) => {
      if (el.id === ident && typechoose === "default") {
        return { ...el, default: event.target.value };
      } else if (el.id === ident && typechoose === "position") {
        return { ...el, position: event.target.value };
      } else if (el.id === ident && typechoose === "closed") {
        return { ...el, closed: event.target.checked };
      } else {
        return { ...el };
      }
    });
    dispatch(addPanel(valPanel));
    setOpen(event.target.checked);
  };
  return <Switch checked={open} onChange={changeHandlerType} />;
};
