import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { NativeSelectType } from "./NativeSelectType";
import { nanoid } from "@reduxjs/toolkit";
import { addCalculate } from "../../store/ActionCreators";
import EditIcon from "@mui/icons-material/Edit";

export function FormDialogChange(props: any) {
  const { row } = props;
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const { catalog } = useAppSelector((state) => state.catalogReducer);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(row?.position ?? "");
  const [count, setCount] = useState(row?.count ?? "");
  const [copy, setCopy] = useState(row?.copy ?? "");
  const [type, setType] = useState(row?.typeequipment ?? "");
  const [checked, setChecked] = useState(row?.main ?? true);
  const [posSpec, setPosSpec] = useState(row?.pos_spec ?? " "); //ДЛЯ СПЕЦИФИКАЦИИ
  const [equiptem, setEquip] = useState(catalog);
  const dispatch = useAppDispatch();
  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }
  function changeHandlerCount(event: ChangeEvent<HTMLInputElement>) {
    setCount(event.target.value);
  }
  function changeHandlerCopy(event: ChangeEvent<HTMLInputElement>) {
    setCopy(event.target.value);
  }
  function changeHandlerPos(event: ChangeEvent<HTMLInputElement>) {
    setPosSpec(event.target.value);
  }
  function changeHandlerType(event: ChangeEvent<HTMLSelectElement>) {
    setType(event.target.value);
    console.log(type);
    setEquip(catalog.filter((el) => el.type === event.target.value));
  }
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleClickOpen = () => {
    console.log(row);
    setOpen(true);
  };
  useEffect(() => {
    setEquip(catalog.filter((el) => el.type === type));
  }, [type]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    // let valuetem = calculate.map((el) => Object.assign({}, el));
    let valuetem = calculate.filter((el) => el.id !== row.id);
    const equip =
      row.typeequipment === type
        ? row.equipment
        : equiptem.map((el) => {
            return {
              id: nanoid(),
              idCalc: row.id,
              name: el.name,
              link: "тест",
              terms: [{ name: "Использование", meaning: "Выбран" }],
            };
          });

    valuetem.push({
      id: row.id,
      position: val,
      typeequipment: type,
      count: count,
      main: checked, //ИСПРАВИТЬ!
      copy: copy,
      pos_spec: posSpec,
      equipment: equip,
    });
    dispatch(addCalculate(valuetem));
    setVal("");
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <EditIcon />
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Добавить</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование позиции"
            type="email"
            fullWidth
            variant="standard"
            value={val}
            onChange={changeHandler}
          />
          <NativeSelectType
            description={true}
            changeHandlerType={changeHandlerType}
            type={type}
          />
          <TextField
            margin="dense"
            id="name"
            label="Количество в спецификации"
            type="email"
            fullWidth
            variant="standard"
            value={count}
            onChange={changeHandlerCount}
          />
          <TextField
            margin="dense"
            id="name"
            label="Количество возможных копий(например отводов)"
            type="email"
            fullWidth
            variant="standard"
            value={copy}
            onChange={changeHandlerCopy}
          />
          <TextField
            margin="dense"
            id="pos_spec"
            label="Позиция в спецификации"
            type="text"
            fullWidth
            variant="standard"
            value={posSpec}
            onChange={changeHandlerPos}
          />

          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleChecked}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Основное оборудование"
            labelPlacement="end"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleAccept}>Изменить</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
