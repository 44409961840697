import { FormPercentLayout } from "./ResulTableLayout";

const FormPercent = (props: any) => {
  const { percent, setPercent } = props;
  const handlerPer = (event: any) => {
    if (Number(event.target.value) > -0.01 && Number(event.target.value) < 50.1)
      setPercent(event.target.value);
  };
  return <FormPercentLayout percent={percent} handlerPer={handlerPer} />;
};

interface ShowPercentProps {
  isFixed: boolean;
  percent: string;
  setPercent: (e: string) => void;
}

export const ShowPrecent = (props: ShowPercentProps) => {
  const { isFixed, percent, setPercent } = props;
  return !isFixed ? (
    <FormPercent percent={percent} setPercent={setPercent} />
  ) : (
    <>{percent} %</>
  );
};
