export const MailSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="20px"
      height="20px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 10000 10000"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      //  xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M1729.81 4816.41c0,-57.26 14.38,-110.82 37.98,-159.21l2149.75 2149.69 -1894.9 1895c-166.96,-34.24 -292.83,-182.15 -292.83,-359l0 -3526.48zm3266.43 -3405.99l2989.5 2989.53 -766.01 766.01 0 -967.94c0,-58.05 -47.12,-105.2 -105.24,-105.2l-4177.64 0c-58.19,0 -105.25,47.15 -105.25,105.2l0 1026.87 -824.88 -824.94 2989.52 -2989.53zm3266.35 3405.99l0 3526.48c0,176.85 -125.86,324.76 -292.74,359l-1894.92 -1895 2149.77 -2149.69c23.6,48.39 37.89,101.95 37.89,159.21zm-5220.48 718.21l0 -1231.29 3967.19 0 0 1172.37 -2013.06 2013.06 -1954.13 -1954.14zm-530.89 3174.73l1654.35 -1654.43 706.66 706.72c32.91,32.85 77.47,51.32 124.01,51.32 46.54,0 91.17,-18.47 123.94,-51.32l706.8 -706.72 1654.28 1654.43 -4970.04 0zm-927.84 -4306.91c-91.63,113.31 -146.75,257.26 -146.75,413.97l0 3526.48c0,363.76 295.9,659.66 659.66,659.66l89.51 0 16.18 16.04 16.06 -16.04 5556.4 0 16.04 16.04 16.21 -16.04 89.42 0c363.76,0 659.66,-295.9 659.66,-659.66l0 -3526.48c0,-156.71 -55.12,-300.74 -146.8,-414.05 0.64,-45.67 -16.21,-91.55 -51.18,-126.43l-3237.61 -3237.55c-65.68,-65.83 -182.22,-65.83 -247.95,0l-3237.56 3237.55c-34.94,34.88 -51.81,80.84 -51.29,126.51z"
          fill="#146EAA"
        />
      </g>
    </svg>
  );
};
