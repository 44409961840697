import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useState, ChangeEvent } from "react";
import { api } from "../../../api";
import { useAppDispatch } from "../../../hooks";
import CloseIcon from "@mui/icons-material/Close";
// import "./Settings.css";

export const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [openT, setOpenT] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [form, setForm] = useState({
    new_password: "",
    current_password: "",
  });
  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((formProps) => ({
      ...formProps,
      [e.target.name]: e.target.value,
    }));
  };
  const [showTwoPassword, setShowTwoPassword] = useState(false);
  const handleClickShowTwoPassword = () => setShowTwoPassword((show) => !show);
  const handleMouseDownTwoPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handlerChange = async () => {
    const resp = await api.setPassword(form);
    if (resp === 204) {
      setOpen(true);
    } else if (resp === "ERR_BAD_REQUEST") {
      setOpenT(true);
    }
  };
  return (
    <>
      <Typography variant="h6">Смена пароля</Typography>
      <div>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">
            Старый пароль
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            name="current_password"
            value={form.current_password}
            onChange={handleForm}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
          <InputLabel htmlFor="standard-adornment-twopassword">
            Новый пароль
          </InputLabel>
          <Input
            id="standard-adornment-twopassword"
            type={showTwoPassword ? "text" : "password"}
            name="new_password"
            value={form.new_password}
            onChange={handleForm}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowTwoPassword}
                  onMouseDown={handleMouseDownTwoPassword}
                >
                  {showTwoPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <Button variant="contained" sx={{ mt: 3, mb: 1 }} onClick={handlerChange}>
        Заменить пароль
      </Button>

      <Collapse in={openT}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenT(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="outlined"
          severity="error"
        >
          Допущена ошибка, повторите еще раз!
        </Alert>
      </Collapse>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          variant="outlined"
          severity="success"
        >
          Успешно!
        </Alert>
      </Collapse>
    </>
  );
};
