import { createSlice } from "@reduxjs/toolkit";
import { DataState, IChoose } from "../../interfaces";
import { getPreCatalog, getPreCatalogNoReg } from "../ThunkCreator";

export const initialState: DataState<IChoose[]> = {
  value: [],
  loading: false,
  error: "",
};

export const ChooseSlice = createSlice({
  name: "listChoose",
  initialState,
  reducers: {
    addChoose(state, action) {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalog.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.choose;
    });
    builder.addCase(getPreCatalog.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPreCatalogNoReg.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogNoReg.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.choose;
    });
    builder.addCase(getPreCatalogNoReg.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ChooseSlice.reducer;
