import { Routes, Route } from "react-router-dom";
import { OfferAllMain } from "../module";

export const OffersAllPage = () => {
  return (
    <Routes>
      <Route path="/" element={<OfferAllMain />} />
    </Routes>
  );
};
