import { useState, useEffect, SyntheticEvent } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { LayoutProps } from "./layoutProps";
import { TabPanelProps } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { offersFull } from "../../store/ThunkCreator";
import { deleteNewOffer } from "../../store/ActionCreators";

const tabvalue = [
  { label: "Объекты", indx: 0, href: "/objects" },
  { label: "Предложения", indx: 1, href: "/offers" },
  { label: "Расчет", indx: 2, href: "/panel" },
  { label: "", indx: 3, href: "/settings" },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const BasicTabsExtra = ({ children }: LayoutProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link to="/objects">
          <Typography
            variant="body2"
            component="div"
            sx={{
              ml: 3,
              pt: 2,
              pb: 2,
              display: "flex",
              // alignItems: "center",
              justifyContent: "start",
              textTransform: "uppercase",
            }}
          >
            {" Объекты"}
          </Typography>
        </Link>
      </Box>
      {children}
    </Box>
  );
};

export const BasicTabs = ({ children }: LayoutProps) => {
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();
  const { value: data } = useAppSelector((state) => state.ObjectsReducer);
  const { value: offer } = useAppSelector((state) => state.OffersReducer);
  const { value: newoffer } = useAppSelector((state) => state.NewOfferReducer);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const calcId = pathname.split("/")[2] ?? "";
  const place = pathname.split("/")[1];
  const getPath = () => {
    if (place === "offers")
      return data.find((el) => el.id.toString() === calcId)?.name;

    if (place === "panel") {
      if (newoffer !== null) {
        return data.find((el) => el.id === newoffer.obj_build)?.name;
      } else {
        return "";
      }
    }
    return "";
  };

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/objects") {
      setValue(0);
    } else if (location.pathname === "/offers") {
      setValue(1);
    } else if (location.pathname.includes("/offers")) {
      setValue(1);
    } else if (location.pathname.includes("/panel")) {
      setValue(2);
    } else {
      setValue(3);
    }
  }, [location]);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/objects");
    } else if (newValue === 1) {
      dispatch(offersFull(""));
      navigate("/offers");
    } else if (newValue === 2) {
      dispatch(deleteNewOffer());
      navigate("/panel");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabvalue.map((el) => (
            <Tab key={el.indx} label={el.label} {...a11yProps(el.indx)} />
          ))}
          {getPath() !== "" ? (
            <Typography
              // variant="h6"
              component="div"
              sx={{
                ml: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
              }}
              align="left"
              justifyContent="center"
            >
              {getPath()}
            </Typography>
          ) : (
            place === "panel" && (
              <Typography
                // variant="h6"
                component="div"
                sx={{
                  ml: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "uppercase",
                  color: "red",
                }}
                align="left"
                justifyContent="center"
              >
                Выберите объект для формирования КП {place}
              </Typography>
            )
          )}
        </Tabs>
      </Box>
      {tabvalue.map((el) => {
        return (
          <TabPanel key={el.indx} value={value} index={el.indx}>
            {children}
          </TabPanel>
        );
      })}
    </Box>
  );
};
