import { useEffect, useState } from "react";
import { ICalculate, TermType } from "../../interfaces";

export interface createheadtyperowsProps {
  id: string;
  name: string;
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined;
}

function createheadtyperows(
  id: string,
  name: string,
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined
): createheadtyperowsProps {
  return { id, name, align };
}

const headtyperows = [
  createheadtyperows("name", "Наименование оборудования", "left"),
  createheadtyperows("verification", "Убрать", "left"),
];

export const useHeadTable = (row: ICalculate): createheadtyperowsProps[] => {
  const [headtype, setHeadtype] =
    useState<createheadtyperowsProps[]>(headtyperows);
  const terms = row.equipment
    .map((el) => {
      return el.terms;
    })
    .flat();
  let uniqueTerms = terms
    .map((el) => el.name)
    .filter((val, ind, arr) => arr.indexOf(val) === ind);
  useEffect(() => {
    let m = uniqueTerms.map((el) => createheadtyperows(el, el, "left"));
    setHeadtype([...headtyperows, ...m]);
  }, []);
  return headtype;
};
