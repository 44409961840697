// import { BlobProvider, Document, Page } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { api } from "../api";
import { PDFViewer } from "@react-pdf/renderer";
// import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
// import { Document } from "react-pdf";
const { Document, Page } = require("react-pdf");

// Create styles

export const MyDocumentPage = () => {
  // const [links, setLinks] = useState<any>();
  // let linss = document.createElement("a");
  // linss.setAttribute("target", "_blank");
  // useEffect(() => {
  //   const link = async () => {
  //     setLinks(await api.getPDF("30"));
  //   };
  //   link();
  //   // linss.href = links;
  //   // linss.click();
  //   console.log(links);
  // }, []);
  const handler = () => {
    api.getPDF("30");
  };

  return (
    <>
      <button onClick={handler}>Нажать</button>
      {/* <a href={links} target="_blank" rel="noreferrer">
        нажать
      </a> */}
      {/* <PDFViewer>
        // <MyDocument />
        //{" "}
      </PDFViewer> */}
    </>
  );
};
