import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, TableCell } from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useAppDispatch } from "../../hooks";
import { achiveOffer, achiveProject } from "../../store/ThunkCreator";
export function DialogAchive({
  id,
  publish,
  archive,
  offer,
}: {
  id: number;
  publish: boolean;
  archive: boolean;
  offer: number;
}) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    console.log(id);
    // ! Здесь диспатч по id запрос и новый запрос таблицы с обновлением, чтобы обность глобальное состояние

    dispatch(achiveOffer({ id, publish, offer }));
    setOpen(false);
  };

  return (
    <>
      <TableCell
        align={"left"}
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      >
        {!publish ? (
          <UnarchiveIcon fontSize="small" color="primary" />
        ) : (
          <ArchiveIcon fontSize="small" color="primary" />
        )}
      </TableCell>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {archive ? "Вернуть из архива?" : "Подтвердите архивацию расчета"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button onClick={handleApprove} autoFocus>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
