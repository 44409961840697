import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SelectUserProps } from "./SelectUser";

const dataRole = [
  { id: 1, name: "расчетчик" },
  { id: 4, name: "менеджер Энергокомфорт" },
  { id: 3, name: "расчетчик Энергокомфорт" },
];

export default function BasicSelect(props: SelectUserProps) {
  const { role, setRole } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setRole({ ...role, role: Number(event.target.value) });
  };

  return (
    <FormControl sx={{ width: "240px", mt: 1, ml: 1 }}>
      <InputLabel id="demo-simple-select-label">Роль</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={role.role.toString()}
        label="Роль"
        onChange={handleChange}
      >
        {dataRole.map((el) => (
          <MenuItem value={el.id}>{el.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
