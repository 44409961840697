import { LocationSVG } from "./LocationSVG";
import { MailSVG } from "./MailSVG";
import { PhoneSVG } from "./PhoneSVG";

import "./Footer.css";
import { InfoSVG } from "./InfoSVG";

export const FooterNew = () => {
  return (
    <footer className="footer">
      <div className="footer_info">
        <p className="footer_title">ООО «Завод Энергокомфорт»</p>
        <p className="footer_phone">
          <span className="footer_small_img">
            <PhoneSVG />
          </span>
          8 (800) 333-59-02
        </p>
        <p className="footer_mail">
          <span className="footer_small_img">
            <MailSVG />
          </span>
          zakaz@energycomfort.ru
        </p>
        <p className="footer_address">
          <span className="footer_small_img">
            <LocationSVG />
          </span>
          344033, Ростов-на-Дону, ул. Всесоюзная, 71, литер ББ, этаж 2
        </p>
      </div>
      <button className="footer_btn">
        <span className="footer_btn_img">
          <InfoSVG />
        </span>
        Сообщить о проблеме
      </button>
    </footer>
  );
};
