import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  useUpdateResult,
  useCustomNavigate,
  useDownloadPDF,
  useEmptyCalc,
} from "../../../hooks";
import {
  createOffer,
  doubleOffer,
  offerFromBuild,
} from "../../../store/ThunkCreator";
import { ColumnHead, columns, TableContainerLayout } from "./ResulTableLayout";
import { ShowPrecent } from "./ShowPercent";
import { RowResult } from "./RowResultCalc";
// import { dublCalc } from "../../../store/ActionCreators";
import { useGetOffer } from "../../../hooks/getOffer";
import { INewOffer, IOffersPatch } from "../../../interfaces";
import { Comments } from "./Comments";
import { api } from "../../../api";
import { NavigateFunction, Location } from "react-router-dom";
import ButtonPDF from "./Popover";
import CircularIntegration from "./CircularIntegration";
import { Actual } from "./Alert";

const handlersavetemplate = (
  newoffer: INewOffer | null,
  location: Location,
  navigate: NavigateFunction,
  total: string,
  percent: string,
  summ: string,
  dispatch: any
) => {
  return () => {
    if (newoffer) {
      let value: IOffersPatch = {
        id: newoffer.id.toString(),
        summ: total,
        discount: percent,
        price: summ,
      };
      api.patchOfferSimple(value);
      setTimeout(() => api.burnPDF(value.id), 1000);

      dispatch(offerFromBuild(newoffer.obj_build.toString()));
    }
    location.state !== null
      ? setTimeout(() => navigate(location.state), 3000)
      : setTimeout(() => navigate(-1), 3000);
  };
};

export function ResultTableCalculate() {
  const isFixed = useEmptyCalc();
  const { navigate, location, forpdf } = useCustomNavigate();
  const rows = useUpdateResult();

  const { value: offer } = useAppSelector((state) => state.OffersReducer);
  const { value: account } = useAppSelector((state) => state.UserReducer);
  const { value: newoffer } = useAppSelector((state) => state.NewOfferReducer);
  // useGetOffer();
  const dispatch = useAppDispatch();
  const [summ, setSumm] = useState("0");
  const [percent, setPercent] = useState(
    location?.state?.percent ?? account.discount
    // offer.find((of) => of.id.toString() === forpdf)?.discount ?? "25"
  );
  const [total, setTotal] = useState("0");
  const handlersave = handlersavetemplate(
    newoffer,
    location,
    navigate,
    total,
    percent,
    summ,
    dispatch
  );

  const handlerduble = () => {
    const temp = offer.find((e) => {
      if (e.id) {
        return e.id.toString() === forpdf;
      }
      return false;
    });
    if (temp) {
      const value = {
        type: 1,
        createdBy: temp.created_by,
        objBuild: "",
        data: rows,
        id: temp.id,
      };
      console.log(value);
      dispatch(doubleOffer(value));
    }
    navigate(`/panel`, { state: `/offers/${newoffer?.obj_build.toString()}` });
  };
  const handlerpdf = useDownloadPDF(forpdf, total, percent, summ);
  const handlerReturn = () => {
    location.state !== null
      ? setTimeout(() => navigate(location.state), 200)
      : setTimeout(() => navigate(-1), 200);
  };
  useEffect(() => {
    let promresult = rows.map((el) => +el.density);
    let result = promresult.reduce(function (sum: any, elem: any) {
      if (elem) {
        return sum + elem;
      }
      return summ;
    }, 0);
    setSumm((+result).toFixed(2).toString());
    setTotal((+result).toFixed(2).toString());
  }, [rows]);
  useEffect(() => {
    let discount = +summ * (1 - +percent / 100);

    setTotal(discount.toFixed(2).toString());
  }, [rows, percent, summ]);

  const rowList = rows.map((row, index) => {
    return (
      <RowResult
        // key={`rr${index}`}
        key={row.idpos}
        account={account}
        row={row}
        discount={percent}
        isFixed={isFixed}
      />
    );
  });
  return (
    <TableContainerLayout
      columnHead={<ColumnHead />}
      rowList={rowList}
      summ={summ}
      formPersent={
        <ShowPrecent
          isFixed={isFixed}
          percent={percent}
          setPercent={setPercent}
        />
      }
      total={total.replace(".", ",")}
      buttonSaveDuble={
        !isFixed ? (
          <CircularIntegration handlersave={handlersave} />
        ) : (
          <Button variant="contained" onClick={handlerduble} fullWidth>
            Дублировать
          </Button>
        )
      }
      buttonPDF={
        !isFixed ? (
          <></>
        ) : (
          <>
            <ButtonPDF isFixed={isFixed} handlerpdf={handlerpdf} />
            <Button
              variant="contained"
              // onClick={handlerduble}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              Проверить актуальность цены
            </Button>
            <Actual />

            <Button
              variant="contained"
              href="https://energycomfort.ru/chertezhi-v-revit/"
              target="_blank"
              // onClick={handlerduble}
              fullWidth
              sx={{ mt: 2 }}
            >
              Ссылка на модели Revit
            </Button>
            <Actual />
          </>
        )

        // <Button
        //   variant="contained"
        //   onClick={handlerpdf}
        //   fullWidth
        //   disabled={!isFixed ? true : undefined}
        // >
        //   Выгрузить КП
        // </Button>
      }
      buttonReturn={
        <Button variant="contained" onClick={handlerReturn} fullWidth>
          Вернуться к предложениям
        </Button>
      }
      comments={<Comments />}
    />
  );
}
