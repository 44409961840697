import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactNode } from "react";
import { useAppSelector } from "../../hooks";
import { IOffers } from "../../interfaces";
import SwitchArchive from "./SwitchArchive";
import React from "react";
import { DialogAchive } from "./DialogAchive";

const OfferHead = (props: any) => {
  const { checked, setChecked } = props;
  const { value: account } = useAppSelector((state) => state.UserReducer);

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: "150" }}>
          Коммерческое предложение
        </TableCell>
        <TableCell style={{ minWidth: "80" }}>Дата создания</TableCell>
        <TableCell style={{ minWidth: "80" }}>Тип оборудования</TableCell>
        <TableCell style={{ minWidth: "80" }}>Стоимость</TableCell>
        {account.company === "Энергокомфорт" && (
          <TableCell style={{ minWidth: "80" }}>Скидка</TableCell>
        )}

        <TableCell style={{ minWidth: "80" }}>Автор</TableCell>
        <TableCell style={{ maxWidth: "50" }} sx={{ m: 0, p: 0 }}>
          <SwitchArchive checked={checked} setChecked={setChecked} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface OfferLayoutProps {
  listOffer: ReactNode;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OfferLayout = (props: OfferLayoutProps) => {
  const { listOffer, checked, setChecked } = props;
  return (
    <>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="sticky table">
          <OfferHead checked={checked} setChecked={setChecked} />
          <TableBody>{listOffer}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface RowResultLayoutProps {
  handlerlink: () => void;
  row: IOffers;
  archive: boolean;
  offer: number;
}

export const RowResultOfferLayout = (props: RowResultLayoutProps) => {
  const { value: account } = useAppSelector((state) => state.UserReducer);
  const { handlerlink, row, archive, offer } = props;
  const forname = row.created_on.split("_").splice(1, 2).join("_");
  const date = row.created_on.replaceAll("_", ".");
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={`row${row.id}`}>
      <TableCell
        align={"left"}
        onClick={handlerlink}
        style={{ cursor: "pointer" }}
      >
        {row.code}_{forname}
      </TableCell>
      <TableCell
        align={"left"}
        onClick={handlerlink}
        style={{ cursor: "pointer" }}
      >
        {date}
      </TableCell>
      <TableCell
        align={"left"}
        onClick={handlerlink}
        style={{ cursor: "pointer" }}
      >
        {row.type}
      </TableCell>
      <TableCell
        align={"left"}
        onClick={handlerlink}
        style={{ cursor: "pointer" }}
      >
        {row.summ.replace(".", ",")}
      </TableCell>
      {account.company === "Энергокомфорт" && (
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.discount.replace(".", ",")}
        </TableCell>
      )}

      <TableCell
        align={"left"}
        onClick={handlerlink}
        style={{ cursor: "pointer" }}
      >
        {row.created_by}
      </TableCell>
      <DialogAchive
        id={row.id}
        publish={row.is_published}
        archive={archive}
        offer={offer}
      />
    </TableRow>
  );
};
