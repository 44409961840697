import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import {
  CartesianGrid,
  Customized,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceDot,
} from "recharts";
import { Box, Button, Divider, Typography } from "@mui/material";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import _ from "lodash";
import { CountPumpType, DataChart } from "../PumpCalculate";
import DenseTable from "./Table";

interface ChartData {
  name: string;
  uv: number;
  pv: number;
}

export type LinearChartProps = {
  data: DataChart[];

  x: string | undefined;
  y: string | undefined;
  zy: { x: number | undefined; y: number | undefined };
  dotline: {
    x: number | undefined;
    y: number | undefined;
  };
  countPump: CountPumpType;
  name: string;
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
}) => {
  if (active && payload && payload.length) {
    console.log(payload[0]);
    return (
      <div>
        <p className="intro">{`Расход, м3/ч: ${label}`}</p>
        <p className="intro">{`Напор 1, м.в.ст.: ${payload[0].value}`}</p>
        {payload[0].payload.tv && (
          <p className="intro">{`Напор 2, м.в.ст.: ${payload[0].payload.tv}`}</p>
        )}

        {/* <p className="intro">{`Напор: ${payload[1].value}`}</p> */}
      </div>
    );
  }

  return null;
};

export const LinearChart: FC<LinearChartProps> = ({
  data,
  x,
  y,
  zy,
  dotline,
  countPump,
  name,
}) => {
  const [showParallelOx, setShowParallelOx] = useState(false);
  const [showParallelOy, setShowParallelOy] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState<DataChart[]>([]);
  const [selectedDot, setSelectedDot] = useState({ x: 0, y: 0 });
  const [clickCoordinates, setClickCoordinates] = useState<ChartData | null>(
    null
  );
  const limitY = 48;
  const [clickPoint, setClickPoint] = useState<{
    x: number;
    pv: number;
    uv: number;
  } | null>(null);
  useEffect(() => {
    // setSelectedDot({ x: point.chartX ?? 0, y: point.chartY ?? 0 });
    if (x && y) {
      const pointX = ((+x + 2) * 530) / (30 + 2);
      const pointY = 300 - (+y * 300) / 40;
      setSelectedDot({ x: pointX, y: pointY });
    }
  }, [x, y]);

  const pointClick = (point: CategoricalChartState, event: SyntheticEvent) => {
    // console.log(point.chartY);
    console.log(point.chartY);
    if (
      point &&
      point.activePayload &&
      point.activePayload[0] &&
      point.activePayload[0].payload
    ) {
      const clickedData: ChartData = point.activePayload[0].payload;
      setClickCoordinates(clickedData);
    }
    if (point?.activePayload) {
      if (
        point.activePayload[0].payload.uv > point.activePayload[0].payload.pv
      ) {
        console.log("Ошибка");
      } else {
        setShowParallelOx(true);
        setShowParallelOy(true);

        const xCoord = point.activePayload[0].payload.name;
        const pvCoord = point.activePayload[0].payload.pv;
        const uvCoord = point.activePayload[0].payload.uv;

        setClickPoint({ x: xCoord, pv: pvCoord, uv: uvCoord });

        const selectedPoint = {
          name: xCoord,
          pv: pvCoord,
          uv: uvCoord,
        };

        setSelectedPoints([...selectedPoints, selectedPoint]);
      }
    }
  };

  const CustomPoint = ({ cx, cy }: any) => (
    <circle cx={cx} cy={cy} r={4} fill="red" stroke="black" strokeWidth={1} />
  );

  return (
    <Box sx={{ mt: 2, ml: 1, mr: 1 }}>
      <LineChart
        width={530}
        height={340}
        data={data}
        // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        onClick={pointClick}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, limitY]} />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dot={false}
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        >
          {clickCoordinates && (
            <ReferenceDot
              x={clickCoordinates.name}
              y={clickCoordinates.pv}
              r={6}
              fill="red"
              stroke="none"
            />
          )}
        </Line>
        <Line type="monotone" dot={false} dataKey="uv" stroke="#82ca9d" />

        {(countPump === "2" || countPump === "3") && (
          <Line type="monotone" dot={false} dataKey="tv" stroke="#8884d8" />
        )}
        {countPump === "3" && (
          <Line type="monotone" dot={false} dataKey="trv" stroke="#8884d8" />
        )}
        {clickCoordinates && (
          <ReferenceLine x={clickCoordinates.name} stroke="black" />
        )}
        {showParallelOx && showParallelOy && !_.isEmpty(clickPoint) ? (
          <>
            <Line
              type="linear"
              dataKey="pv"
              stroke="#ff0000"
              dot={false}
              animationDuration={0}
              points={[
                { x: clickPoint.x, y: clickPoint.pv },
                { x: 730, y: clickPoint.pv },
              ]}
            />
          </>
        ) : null}
        <Customized component={<CustomPoint cx={zy.x} cy={zy.y} />} />
        <Customized
          component={<CustomPoint cx={dotline.x ?? 0} cy={dotline.y ?? 0} />}
        />
      </LineChart>
      <DenseTable name={name} />
      {/* <Box
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          mb: 1,
        }}
      >
        <Typography variant="h5"> Текст</Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography variant="h5"> Текст</Typography>
      </Box> */}
      <Button variant="contained" fullWidth>
        Выбрать
      </Button>
    </Box>
  );
};
