import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SettingChoose } from "./SettingChoose";
import { IEquipment } from "../../interfaces";
import { Layout } from "../Layout/layout";
import { getPreCatalogM } from "../../store/ThunkCreator";

export const Calculate = () => {
  const { calcId } = useParams();
  const navigate = useNavigate();
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const [accounts, setAccounts] = useState<IEquipment>({
    id: "",
    idCalc: "",
    name: "",
    terms: [],
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPreCatalogM("2"));
  }, []);
  useEffect(() => {
    const accounting = calculate.map((el) => el.equipment).flat();
    const ValidRoomId = accounting.find(
      (account: IEquipment) => account.id === calcId
    );
    if (ValidRoomId) {
      setAccounts(ValidRoomId);
    }
    if (!ValidRoomId && calcId) {
      navigate("/settingswatter");
    }
  }, [calcId, navigate]);
  return (
    <Layout>
      <SettingChoose calcId={calcId} accounts={accounts} />
    </Layout>
  );
};
