import { useLocation, Navigate } from "react-router-dom";
import { useRequire } from "../components";
import { SignUp } from "../module/SignUp/SignUp";

export const SignUpPage = (props: any) => {
  const isAuth = useRequire();
  let location = useLocation();

  if (isAuth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return <SignUp />;
  }
};
