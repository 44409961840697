import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";

export const useRequire = () => {
  //   const [session, setSession] = useState<Boolean>(false);
  const { value: data } = useAppSelector((state) => state.UserReducer);
  if (data.username) {
    return true;
  } else {
    return false;
  }
};
