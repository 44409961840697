import { useLocation, Navigate } from "react-router-dom";
import { useRequire } from "../components";

import { Login } from "../module";
export const LoginPage = (props: any) => {
  const isAuth = useRequire();
  let location = useLocation();

  if (isAuth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return <Login />;
  }
};
