import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { DialogAchive } from "./DialogAchive";
import SwitchArchive from "./SwitchArchive";

function RowResult(props: any) {
  let navigate = useNavigate();
  const { row, archive } = props;

  const handlerlink = () => {
    navigate(`/offers/${row.id}`);
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.name}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.created_on}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.latest_offer_date}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer", maxWidth: "50" }}
        >
          {row.created_by}
        </TableCell>
        <DialogAchive
          id={row.id}
          publish={row.is_published}
          archive={archive}
        />
      </TableRow>
    </>
  );
}

const handlerArchive = (cheked: boolean, published: boolean) => {
  if (!cheked) return published;
  if (cheked) return !published;
};

export function ObjectsForOffers() {
  const { value: data } = useAppSelector((state) => state.ObjectsReducer);

  const [checked, setChecked] = React.useState(false);

  return (
    <>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "150" }}>
                Наименование объекта
              </TableCell>
              <TableCell style={{ minWidth: "100" }}>Дата создания</TableCell>
              <TableCell style={{ minWidth: "100" }}>Дата обновления</TableCell>
              <TableCell style={{ minWidth: "100" }}>Автор</TableCell>
              <TableCell style={{ maxWidth: "50" }} sx={{ m: 0, p: 0 }}>
                <SwitchArchive checked={checked} setChecked={setChecked} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((el) => handlerArchive(checked, el.is_published))
              .map((row: any, index: number) => {
                if (row) {
                  return (
                    <RowResult key={`rr${index}`} row={row} archive={checked} />
                  );
                } else {
                  return <></>;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
