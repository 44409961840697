import * as React from "react";

import { Button } from "@mui/material";
import { GroupButton } from "./GroupButton";
import {
  getPreCatalogNoReg,
  setResultNoReg,
} from "../../../../store/ThunkCreator";
import {
  useEmptyCalc,
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks";

const func = (exep: any, name: any, meaning: any) => {
  let temp = exep.map((em: any) => {
    if (
      Boolean(em.find((el: any) => el.name === name && el.meaning === meaning))
    ) {
      return em;
    } else {
      return [];
    }
  });
  let result = temp.map((em: any) =>
    em.filter((et: any) => et.name !== name && et.meaning !== meaning)
  );
  result = result.filter((el: any) => el.length === 1);
  return result;
};
const funcexep = (exep: any, choose: any) => {
  let temp = choose.map((el: any) => {
    return func(exep, el.name, el.meaning);
  });
  return temp.flat(3);
};

export const Panel = (props: any) => {
  const { setIsResult } = props;
  const isFixed = useEmptyCalc();
  const { value: data } = useAppSelector((state) => state.PanelUserReducer);
  const { value: listExceptCalc } = useAppSelector(
    (state) => state.ExceptCalcReducer
  );
  const { value: choose } = useAppSelector((state) => state.ChooseReducer);
  const [alignment, setAlignment] = React.useState(choose);
  const [excep, setExcep] = React.useState(funcexep(listExceptCalc, choose));
  const [disabled, setDisabled] = React.useState(false);
  const dispatch = useAppDispatch();

  const handlerchoose = (e: any) => {
    dispatch(setResultNoReg(choose));
    setIsResult(true);
  };
  // const [data] = React.useState(mok);
  React.useEffect(() => {
    setExcep(funcexep(listExceptCalc, choose));
  }, [choose]);
  React.useEffect(() => {
    let isChoose = Boolean(
      choose.find(
        (el) =>
          (el.name === "Количество отводов" && el.meaning === "1") ||
          el.meaning === "не выбран"
      )
    );
    isChoose ? setDisabled(true) : setDisabled(false);
  }, [choose]);
  React.useEffect(() => {
    dispatch(getPreCatalogNoReg("загрузка"));
  }, []);

  function isExist(choose: any, panel: any, el: any) {
    let count = choose.find((item: any) => item.name === "Количество отводов");
    let findDn = el.name.indexOf("Диаметр квартирного отвода");
    let mean = count.meaning === "не выбран" ? 1 : +count.meaning;
    if (findDn !== -1) {
      let temp = el.name.split(" ");
      let isTemp = +temp[3];
      if (isTemp > mean) {
        return false;
      }
    }
    if (el.name) return true;
  }
  if (!isFixed) {
    return (
      <>
        {data.map((el, indx) => {
          if (el.closed === true && isExist(choose, data, el)) {
            return (
              <GroupButton
                key={`gb${indx}`}
                name={el.name}
                data={el.meaning}
                choose={choose}
                exceptcalc={listExceptCalc}
                excep={excep}
              />
            );
          } else {
            return <></>;
          }
        })}

        <hr />
        <Button
          variant="contained"
          sx={{ mb: 1 }}
          onClick={handlerchoose}
          disabled={disabled}
        >
          Загрузить решение
        </Button>
      </>
    );
  } else {
    return <></>;
  }
};
