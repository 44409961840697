import { LinearChart } from "../charts/Chart";
import { PreResultProps } from "./PreResultProps";

export function PreResultPump(props: PreResultProps) {
  const { isResult, setIsResult, data, xy, zy, dotline, countPump, name } =
    props;
  return (
    <>
      <LinearChart
        data={data}
        x={xy.x}
        y={xy.y}
        zy={zy}
        dotline={dotline}
        countPump={countPump}
        name={name}
      />
    </>
  );
}
