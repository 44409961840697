import { Route, Routes } from "react-router-dom";
import { Calculate } from "../module/demo/calculate";

export const DemoPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Calculate />} />
    </Routes>
  );
};
