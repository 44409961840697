import { Panel } from "../panel/Panel";
import { ChangeEvent, useEffect, useState } from "react";
import { PreResultPump } from "./preresult/PreResultPump";
import { PanelLayout } from "./PanelLayout";
import { CustomizedSnackbars } from "./Alert";
import { ResultTableCalculate } from "../result";
import { InputAdornments } from "./InputData";
import { SelectChangeEvent } from "@mui/material";

type InputItem = { type: string; subtype: string; name: string };
type OutputItem = { key: string; value: string[] };
// ! Группировака элементов
function groupByType(input: InputItem[]): OutputItem[] {
  const grouped: Record<string, Set<string>> = {};

  // Группировка элементов по типу
  input.forEach((item) => {
    if (!grouped[item.type]) {
      grouped[item.type] = new Set();
    }
    grouped[item.type].add(item.subtype);
  });

  // Преобразование в требуемый формат
  return Object.keys(grouped).map((type) => ({
    key: type,
    value: Array.from(grouped[type]),
  }));
}
function groupBySubType(input: InputItem[]): OutputItem[] {
  const grouped: Record<string, Set<string>> = {};

  // Группировка элементов по типу
  input.forEach((item) => {
    if (!grouped[item.subtype]) {
      grouped[item.subtype] = new Set();
    }
    grouped[item.subtype].add(item.name);
  });

  // Преобразование в требуемый формат
  return Object.keys(grouped).map((subtype) => ({
    key: subtype,
    value: Array.from(grouped[subtype]),
  }));
}

export interface DataArray {
  key: string;
  value: string[];
}

const data = [
  {
    type: "Насос циркуляционный CNP",
    subtype: "TD",
    name: "TD 100-40G/2 380V",
  },
  {
    type: "Насос циркуляционный CNP",
    subtype: "TD",
    name: "TD 80-18G/2SWHCJ",
  },
  {
    type: "Насос циркуляционный CNP",
    subtype: "TD",
    name: "TD 50-15G/2SWHCJ",
  },
  {
    type: "Насос циркуляционный CNP",
    subtype: "TD",
    name: "TD 65-15/2 380V",
  },
  {
    type: "Насос циркуляционный CNP",
    subtype: "CHLF(T)",
    name: "CHLFT 4-60 LSWPC",
  },
  {
    type: "Насос циркуляционный CNP",
    subtype: "CDM(F)",
    name: "CDM 1-7 FSWPC 0,37 кВт 3х220В/3х380В, 50 Гц, чугун, 70 С",
  },
  {
    type: "Насос LEO",
    subtype: "Горизонтальный EDH",
    name: "EDH 2-40 (380 В)",
  },
  {
    type: "Насос LEO",
    subtype: "Горизонтальный центробежный XST",
    name: "XST80-160/110  380 В, 11кВт",
  },
  {
    type: "Насос LEO",
    subtype: "Вертикальный многоступенчатый EVP6",
    name: "EVP6-5 (2,2 кВт) 380-415V",
  },
];
const dataFirst = groupByType(data);

const dataSecond = groupBySubType(data);

const dataThreed = [
  { key: "1A", value: ["Name1", "Name2", "Name3"] },
  { key: "2A", value: ["Name4", "Name5", "Name6"] },
  { key: "3A", value: ["Name7", "Name8", "Name19"] },
];
// генерация числового массива
function generateNumberArray(step: number, stepsCount: number): number[] {
  let array = [];
  for (let i = 0; i < stepsCount; i++) {
    array.push(i * step);
  }
  return array;
}

export type DataChart = {
  name: string;
  pv?: string;
  uv?: string;
};

function findQuadraticCoefficients(
  x: number,
  y: number
): [number, number, number] {
  const a = y / (x * x);
  const b = 0;
  const c = 0;

  return [a, b, c];
}
// ! Нахождение точки пересечения
function newtonMethod(
  a: number,
  b: number,
  c: number,
  d: number,
  tolerance: number = 0.01,
  maxIterations: number = 1000
): number {
  const initialGuess = 10;
  const f = (x: number) => a * x ** 3 + b * x ** 2 + c * x + d;
  const df = (x: number) => 3 * a * x ** 2 + 2 * b * x + c;
  let x = initialGuess;
  for (let i = 0; i < maxIterations; i++) {
    let fx = f(x);
    let dfx = df(x);

    if (Math.abs(fx) < tolerance) {
      // Решение найдено
      return x;
    }

    if (dfx === 0) {
      throw new Error(
        "Нулевая производная. Метод Ньютона не может продолжить."
      );
    }

    x = x - fx / dfx;
  }

  throw new Error("Превышено максимальное количество итераций");
}

const koef = [
  -8.1345125462772 * Math.pow(10, -6),
  6.2881236411 * Math.pow(10, -4),
  -0.02349,
  44.4,
];
export type CountPumpType = "0" | "1" | "2" | "3" | "4" | "5";

export const PumpCalculate = (): JSX.Element => {
  const [isResult, setIsResult] = useState<Boolean>(false);
  const [xy, setXy] = useState<{
    x: string | undefined;
    y: string | undefined;
  }>({ x: undefined, y: undefined });
  const [zy, setZy] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const [dotline, setDotline] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const [countPump, setCountPump] = useState<CountPumpType>("0");

  const [data, setData] = useState<DataChart[]>([]);
  // const arrcount = generateNumberArray(0.1, 301);
  const calculateFunctionOne = (x: number): number =>
    koef[0] * Math.pow(x, 3) + koef[1] * Math.pow(x, 2) + koef[2] * x + koef[3];

  const calculateFunctionTwo = (x: number, a: number): number =>
    a * Math.pow(x, 2);
  useEffect(() => {
    // const chartData: DataChart[] = [];
    if (xy.x && xy.y) {
      if (countPump === "1") {
        const arrcount = generateNumberArray(0.1, 1401);
        const [a] = findQuadraticCoefficients(+xy.x, +xy.y);
        const chartData: DataChart[] = arrcount.map((el) => {
          const y2 = calculateFunctionTwo(el - 1, a);
          const y1 = calculateFunctionOne(el);
          if ((y2 > y1 || y2 > 49) && el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
            };
          } else if (el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
              uv: calculateFunctionTwo(el, a).toFixed(2),
            };
          } else {
            return {
              name: `${el.toFixed(0)}`,
            };
          }
        });
        setData(chartData);
        try {
          const res = newtonMethod(koef[0], koef[1] - a, koef[2], koef[3]);
          const resy = a * res ** 2;
          // const cx = ((res + 2) * (530 - 20 - 30)) / (30 + 2);
          // const cz = 360 - (resy * 360) / 50;
          const cx = ((+xy.x ?? 0) * 15) / 4.6 + 67;
          const cz = -6 * (+xy.y ?? 0) + 300;
          setZy((prev) => {
            return { x: cx, y: cz };
          });
          // const pointX = ((res + 2) * (530 - 20 - 30)) / (30 + 2) + 5;
          // 65
          const pointX = (res * 15) / 4.6 + 67;
          const pointY = -6 * resy + 300;
          // const pointY = 360 - (resy * 360) / 40;
          setDotline((prev) => {
            return { x: pointX, y: pointY };
          });
        } catch (error) {
          console.error(error);
        }
      } else if (countPump === "2") {
        const arrcount = generateNumberArray(0.2, 1401);
        const [a] = findQuadraticCoefficients(+xy.x, +xy.y);
        const chartData: DataChart[] = arrcount.map((el) => {
          const y2 = calculateFunctionTwo(el - 1, a);
          const y1 = calculateFunctionOne(el);
          if ((y2 > y1 || y2 > 49) && el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
              tv: calculateFunctionOne(el / 2).toFixed(2),
            };
          } else if (el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
              tv: calculateFunctionOne(el / 2).toFixed(2),
              uv: calculateFunctionTwo(el, a).toFixed(2),
            };
          } else if (el >= 130 && el < 260) {
            return {
              name: `${el.toFixed(1)}`,
              tv: calculateFunctionOne(el / 2).toFixed(2),
            };
          } else {
            return {
              name: `${el.toFixed(0)}`,
            };
          }
        });
        setData(chartData);
        try {
          const res = newtonMethod(koef[0], koef[1] - a, koef[2], koef[3]);
          const resy = a * res ** 2;
          // const cx = ((res + 2) * (530 - 20 - 30)) / (30 + 2);
          // const cz = 360 - (resy * 360) / 50;
          const cx = ((+xy.x ?? 0) * 15) / 4.6 / 2 + 67;
          const cz = -6 * (+xy.y ?? 0) + 300;
          setZy((prev) => {
            return { x: cx, y: cz };
          });
          // const pointX = ((res + 2) * (530 - 20 - 30)) / (30 + 2) + 5;
          // 65
          const pointX = (res * 15) / 4.6 / 2 + 67;
          const pointY = -6 * resy + 300;
          // const pointY = 360 - (resy * 360) / 40;
          setDotline((prev) => {
            return { x: pointX, y: pointY };
          });
        } catch (error) {
          console.error(error);
        }
      } else if (countPump === "3") {
        const arrcount = generateNumberArray(0.3, 1401);
        const [a] = findQuadraticCoefficients(+xy.x, +xy.y);
        const chartData: DataChart[] = arrcount.map((el) => {
          const y2 = calculateFunctionTwo(el - 1, a);
          const y1 = calculateFunctionOne(el);
          if ((y2 > y1 || y2 > 49) && el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
              tv: calculateFunctionOne(el / 2).toFixed(2),
              trv: calculateFunctionOne(el / 3).toFixed(2),
            };
          } else if (el < 130) {
            return {
              name: `${el.toFixed(1)}`,
              pv: calculateFunctionOne(el).toFixed(2),
              tv: calculateFunctionOne(el / 2).toFixed(2),
              uv: calculateFunctionTwo(el, a).toFixed(2),
              trv: calculateFunctionOne(el / 3).toFixed(2),
            };
          } else if (el >= 130 && el < 260) {
            return {
              name: `${el.toFixed(1)}`,
              tv: calculateFunctionOne(el / 2).toFixed(2),
              trv: calculateFunctionOne(el / 3).toFixed(2),
            };
          } else if (el >= 260 && el < 390) {
            return {
              name: `${el.toFixed(1)}`,
              trv: calculateFunctionOne(el / 3).toFixed(2),
            };
          } else {
            return {
              name: `${el.toFixed(0)}`,
            };
          }
        });
        setData(chartData);
        try {
          const res = newtonMethod(koef[0], koef[1] - a, koef[2], koef[3]);
          const resy = a * res ** 2;
          // const cx = ((res + 2) * (530 - 20 - 30)) / (30 + 2);
          // const cz = 360 - (resy * 360) / 50;
          const cx = ((+xy.x ?? 0) * 15) / 4.6 / 3 + 67;
          const cz = -6 * (+xy.y ?? 0) + 300;
          setZy((prev) => {
            return { x: cx, y: cz };
          });
          // const pointX = ((res + 2) * (530 - 20 - 30)) / (30 + 2) + 5;
          // 65
          const pointX = (res * 15) / 4.6 / 3 + 67;
          const pointY = -6 * resy + 300;
          // const pointY = 360 - (resy * 360) / 40;
          setDotline((prev) => {
            return { x: pointX, y: pointY };
          });
        } catch (error) {
          console.error(error);
        }
      }

      // const height = 420;
      // const dY0 = 0;
      // const dY1 = 60;
      // const topPadding = 5;
      // const bottomPadding = 60;
      // pointX = (dataValueX - domainX[0]) * (width - leftPadding - rightPadding) / (domainX[1] - domainX[0]) + leftPadding;
      //pointY = height - (dataValueY - domainY[0]) * (height - topPadding - bottomPadding) / (domainY[1] - domainY[0]) - topPadding;
    }
  }, [xy, countPump]);
  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setXy((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handlerCount = (e: SelectChangeEvent<CountPumpType>) => {
    console.log(countPump);
    setCountPump((prev) => {
      return String(e.target.value) as CountPumpType;
    });
  };

  return (
    <>
      <CustomizedSnackbars />
      <PanelLayout
        panel={
          <InputAdornments
            xy={xy}
            handlerChange={handlerChange}
            handlerCount={handlerCount}
            countPump={countPump}
            dataFirst={dataFirst}
            dataSecond={dataSecond}
            dataThreed={dataThreed}
          />
        }
        preresult={
          <PreResultPump
            setIsResult={setIsResult}
            isResult={isResult}
            data={data}
            xy={xy}
            zy={zy}
            dotline={dotline}
            countPump={countPump}
            name={"TD 100-40G/2 380V"}
          />
        }
        result={<ResultTableCalculate />}
      />
    </>
  );
};
