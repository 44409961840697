import {
  TableBody as MUITableBody,
  TableCell,
  TableRow,
  TableHead,
  Input,
} from "@mui/material";

interface createheadtyperowsProps {
  id: string;
  name: string;
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined;
}

function createheadtyperows(
  id: string,
  name: string,
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined
): createheadtyperowsProps {
  return { id, name, align };
}

const headtyperows = [
  createheadtyperows("name", "Наименование оборудования", "left"),
  createheadtyperows("value", "Позиция", "left"),
  createheadtyperows("value", "Количество", "left"),
];

export function CollapsePanel(props: { row: any; account: any }) {
  const { row, account } = props;
  return (
    <>
      <TableHead>
        <TableRow>
          {headtyperows.map((headrow: createheadtyperowsProps) => (
            <TableCell key={`ht${headrow.id}`} align={headrow.align}>
              {headrow.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <MUITableBody>
        {row.specification
          .filter((el: any) => {
            if (el.main !== false && account.company !== "Энергокомфорт") {
              return true;
            } else if (
              el.main !== true &&
              account.company === "Энергокомфорт"
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((spec: any, ind: number) => (
            <TableRow key={`colapse${ind}`}>
              <TableCell key={`describe${ind}`} align={"left"}>
                {spec.describe}
              </TableCell>
              <TableCell key={`position${ind}`} align={"left"}>
                {spec.position}
              </TableCell>
              <TableCell key={`count${ind}`} align={"left"}>
                {spec.count}
              </TableCell>
            </TableRow>
          )) ?? ""}
      </MUITableBody>
    </>
  );
}
