import { useState, useEffect, SyntheticEvent } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LayoutProps } from "./layoutProps";
import { TabPanelProps } from "../../interfaces";

// datasettings;
const tabvalue = [
  { label: "Каталог оборудования", indx: 0, href: "/catalog" },
  { label: "Расчетный механизм", indx: 1, href: "/datasettings" },
  { label: "Настройка панели", indx: 2, href: "/settingchoose" },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const BasicTabs = ({ children }: LayoutProps) => {
  const [value, setValue] = useState<number>(0);
  const navigate = useNavigate();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/catalog") {
      setValue(0);
    }
    if (location.pathname === "/datasettings") {
      setValue(1);
    }
    if (location.pathname === "/settingchoose") {
      setValue(2);
    }
  }, [location]);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/catalog");
    }
    if (newValue === 1) {
      navigate("/datasettings");
    }
    if (newValue === 2) {
      navigate("/settingchoose");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabvalue.map((el) => {
            return (
              <Tab key={el.indx} label={el.label} {...a11yProps(el.indx)} />
            );
          })}
        </Tabs>
      </Box>
      {tabvalue.map((el) => {
        return (
          <TabPanel key={el.indx} value={value} index={el.indx}>
            {children}
          </TabPanel>
        );
      })}
    </Box>
  );
};
