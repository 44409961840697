import { Routes, Route } from "react-router-dom";
import { TabFinish, Calculate } from "../managewatter";

export const WatterSettingsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<TabFinish />} />
      <Route path={`/:calcId`} element={<Calculate />} />
    </Routes>
  );
};
