// import { resultCalcData } from "../components/Panel/TabsCalculator";
import { AppDispatch } from "./index";
import { ResultSlice } from "./slices/ResultSlice";
import { ChooseSlice } from "./slices/ChooseSlice";
import { resultCalcData } from "../interfaces";
import { UserSlice } from "./slices/UserSlice";
import { DublicatCalcSlice } from "./slices/DublicatCalcSilce";
import { NewOfferSlice } from "./slices/NewOfferSlice";
import { PreCatalogSlice } from "./slices/PreCatalogSlice";
import { ChangeSlice } from "./slices/ChangeSlice";
import { PanelSlice } from "./slices/PanelSlice";
import { ManageCalcSlice } from "./slices/ManageCalcSlice";
import { ChangeResultSlice } from "./slices/ChangeResultSlice";

export const addChoose = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(ChooseSlice.actions.addChoose(value));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const userAuthenticatedOut = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(UserSlice.actions.userAuthenticatedOut(value));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const dublCalc = (value: resultCalcData[]) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(DublicatCalcSlice.actions.dublCalc(value));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const deleteNewOffer = () => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(NewOfferSlice.actions.deleteNewOffer());
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addComment = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(NewOfferSlice.actions.addComment(value));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addCalculate = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(ManageCalcSlice.actions.addCalculate({ value }));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addValue = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(PreCatalogSlice.actions.addValue({ value }));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addChange = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(ChangeSlice.actions.addChange({ value }));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addPanel = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(PanelSlice.actions.addPanel({ value }));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};

export const addChangeResult = (value: any) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(ChangeResultSlice.actions.addChangeResult({ value }));
    } catch (e) {
      console.log("Ошибка");
    }
  };
};
