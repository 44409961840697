import { Grid, Button, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useUpdateOffer,
  useCreateOffer,
  useUpdateOfferAll,
  useCreateOfferWater,
  useCreateOfferPump,
} from "../../hooks";
import { OffersList } from "..";
import { OffersListFull } from "./OfferListFull";
import { OffersListAll } from "./OfferListAll";

interface ButtonOfferProps {
  handler: () => void;
  handlerwater: () => void;
  handlerpump: () => void;
}

interface ButtonCalcProps {
  id: Number;
  name: string;
  disabled: undefined | true;
}

const ButtonCalc: ButtonCalcProps[] = [
  {
    name: "Коллектор отопления",
    disabled: undefined,
    id: 1,
  },
  { name: "Коллектор водоснабжения", disabled: true, id: 2 },
  { name: "Насосная станция", disabled: true, id: 3 },
  { name: "Блочный тепловой пункт", disabled: true, id: 4 },
  { name: "Свободный расчет", disabled: true, id: 5 },
];

const ButtonOffer = (props: ButtonOfferProps) => {
  const { handler, handlerwater, handlerpump } = props;

  return (
    <>
      <h4>Сформировать предложение на:</h4>
      <Button
        key={`button1`}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handler}
      >
        Коллектор отопления
      </Button>
      <Button
        key={`button2`}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handlerwater}
      >
        Коллектор водоснабжения
      </Button>
      <Button
        key={`button3`}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handlerpump}
        // disabled={true}
      >
        Насосная станция
      </Button>
      <Button
        key={`button4`}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handler}
        disabled={true}
      >
        Блочный тепловой пункт
      </Button>
      <Button
        key={`button5`}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: 2 }}
        onClick={handler}
        disabled={true}
      >
        Свободный расчет
      </Button>
    </>
  );
};

export const OfferMain = () => {
  const { calcId } = useParams();
  const isEmpty = calcId ? true : false;

  const handler = useCreateOffer();
  const handlerwater = useCreateOfferWater();
  const handlerpump = useCreateOfferPump();
  useUpdateOffer();

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="space-between"
      columns={12}
      alignItems="flex-start"
    >
      {isEmpty ? (
        <Grid item xs={4}>
          <ButtonOffer
            handler={handler}
            handlerwater={handlerwater}
            handlerpump={handlerpump}
          />
        </Grid>
      ) : (
        <></>
      )}
      {isEmpty ? (
        <Grid item xs={8}>
          <OffersList offer={calcId ? +calcId : 0} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <OffersListFull />
        </Grid>
      )}
    </Grid>
  );
};

export const OfferAllMain = () => {
  useUpdateOfferAll();

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="space-between"
      columns={12}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <OffersListAll />
      </Grid>
    </Grid>
  );
};
