import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getCalcFromOffers } from "../../store/ThunkCreator";
import { OfferLayout, RowResultOfferLayout } from "./OfferLayout";
import { ReactNode, useState } from "react";
import { IOffers } from "../../interfaces";
// import { dublCalc } from "../../store/ActionCreators";
import { TableCell, TableRow } from "@mui/material";

function RowResult(props: any) {
  let navigate = useNavigate();
  const { row, archive, offer } = props;

  const dispatch = useAppDispatch();

  const handlerlink = () => {
    // dispatch(dublCalc([]));
    dispatch(getCalcFromOffers(row.id));
    navigate(`/panel/${row.id}`, {
      state: {
        percent: row.discount,
        comment: row.comment,
        price: row.price,
        summ: row.summ,
      },
    });
  };
  return (
    <RowResultOfferLayout
      handlerlink={handlerlink}
      row={row}
      archive={archive}
      offer={offer}
    />
  );
}

const handlerArchive = (cheked: boolean, published: boolean) => {
  if (!cheked) return published;
  if (cheked) return !published;
};

export function OffersList({ offer }: { offer: number }) {
  const { value: offers } = useAppSelector((state) => state.OffersReducer);

  const [checked, setChecked] = useState(false);
  const listOffer: ReactNode[] = offers
    .filter((e) => e.summ !== "0.00" && e.summ !== "0")
    .filter((el) => handlerArchive(checked, el.is_published))
    .map((row: IOffers) => {
      return row ? (
        <>
          <RowResult
            key={`result${row.id}`}
            row={row}
            archive={checked}
            offer={offer}
          />
          <TableRow hover role="checkbox" tabIndex={-1} key={`row${row.id}`}>
            <TableCell />
            <TableCell align={"left"} colSpan={5}>
              КОММЕНТАРИЙ: {row.comment}
            </TableCell>
            <TableCell />
          </TableRow>
        </>
      ) : (
        <></>
      );
    });
  return (
    <OfferLayout
      listOffer={listOffer}
      checked={checked}
      setChecked={setChecked}
    />
  );
}
