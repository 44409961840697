import { useState } from "react";
import {
  TableCell,
  TableRow,
  Box,
  Button,
  IconButton,
  Collapse,
  Table,
  Switch,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { CollapseRow } from "./CollapseRow";
import { ICalculate, TableRowProps } from "../../interfaces";
import { addCalculate } from "../../store/ActionCreators";
import { FormDialogChange } from "./FormDialogChange";

const ariaLabel = { "aria-label": "description" };

interface RowProps {
  row: ICalculate;
  labelId: string;
}

export function Row(props: RowProps) {
  const { row, labelId } = props;
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [dis, setDis] = useState<Boolean>(true);

  // Функция для изменения основного и не основного оборудования
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // ЗДЕСЬ addCalculate на изменение main
    let valuetem = calculate.map((el) => {
      if (el.id === row.id) {
        return { ...el, main: event.target.checked };
      } else {
        return Object.assign({}, el);
      }
    });
    dispatch(addCalculate(valuetem));
  };

  const handlerdic = () => {
    if (dis === false) {
      setDis(true);
    }
    if (dis === true) {
      setDis(false);
    }
  };
  const handlePos = (event: React.ChangeEvent<HTMLInputElement>) => {
    let valuetem = calculate.map((el) => {
      if (el.id === row.id) {
        return { ...el, pos_spec: event.target.value };
      } else {
        return Object.assign({}, el);
      }
    });
    dispatch(addCalculate(valuetem));
  };
  const deleteElement = () => {
    let valtemp = calculate.filter((el) => el.position !== row.position);
    dispatch(addCalculate(valtemp));
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.position}
        </TableCell>

        <TableCell align="left">{row.typeequipment}</TableCell>
        <TableCell>{row.count}</TableCell>
        <TableCell>{row.copy}</TableCell>
        <TableCell>
          <FormDialogChange row={row} />
        </TableCell>
        <TableCell>
          <Button onClick={deleteElement}>
            <DeleteIcon />
          </Button>
        </TableCell>
        <TableCell>
          <Switch
            checked={row.main}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TableCell>
        <TableCell>
          <TextField
            margin="dense"
            id="pos_spec"
            type="text"
            size="small"
            sx={{ width: "50px" }}
            variant="standard"
            value={row.pos_spec}
            onChange={handlePos}
          />
          {/* <Switch
            checked={row.pos_spec}
            onChange={handlePos}
            inputProps={{ "aria-label": "controlled" }}
          /> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <CollapseRow row={row} />
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

{
  /* ; */
}
