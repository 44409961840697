import { useNavigate, useParams } from "react-router-dom";
// import { dublCalc } from "../store/ActionCreators";
import { createOffer } from "../store/ThunkCreator";
import { useAppDispatch, useAppSelector } from "./redux";

export const useCreateOffer = () => {
  const { value: user } = useAppSelector((state) => state.UserReducer);
  const { calcId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return () => {
    if (calcId) {
      const value = {
        type: 1,
        createdBy: user.idUsername,
        objBuild: +calcId,
      };
      // dispatch(dublCalc([]));
      dispatch(createOffer(value));
    }
    setTimeout(() => navigate(`/panel`), 200);
  };
};

//
export const useCreateOfferWater = () => {
  const { value: user } = useAppSelector((state) => state.UserReducer);
  const { calcId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return () => {
    if (calcId) {
      const value = {
        type: 2,
        createdBy: user.idUsername,
        objBuild: +calcId,
      };
      // dispatch(dublCalc([]));
      dispatch(createOffer(value));
    }
    setTimeout(() => navigate(`/panel`), 200);
  };
};

export const useCreateOfferPump = () => {
  const navigate = useNavigate();
  return () => setTimeout(() => navigate(`/pump`), 200);
};
