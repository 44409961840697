import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import AppointRole from "./AppointRole/AppointRole";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import CreatePrtner from "./CreatePartner/CreatePartner";
import "./Settings.css";

export const Settings = () => {
  const { value: account, error } = useAppSelector(
    (state) => state.UserReducer
  );

  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="md">
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#EAF3EC",
            height: "25ch",
            mb: 1,
            p: 1,
          }}
        >
          <ChangePassword />
        </Box>
        {account.role === "менеджер продукта" && (
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#EAF3EC",
              height: "20ch",
              mb: 1,
              p: 1,
            }}
          >
            <Typography variant="h6">Управление менеджера проекта</Typography>
            <CreatePrtner />
            <AppointRole />
          </Box>
        )}
        {(account.role === "менеджер продукта" ||
          account.role === "инженер продукта" ||
          account.role === "расчетчик Энергокомфорт" ||
          account.role === "менеджер Энергокомфорт") && (
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#EAF3EC",
              height: "20ch",
              mb: 1,
              p: 1,
            }}
          >
            <Typography variant="h6">Расчеты и объекты</Typography>
            {/* <Button
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              onClick={() => navigate("/objects")}
            >
              Посмотреть все объекты
            </Button> */}
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => navigate("/alloffers")}
            >
              Посмотреть все расчеты
            </Button>
          </Box>
        )}
        {account.role === "инженер продукта" && (
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#EAF3EC",
              height: "15ch",
              mb: 1,
              p: 1,
            }}
          >
            <Typography variant="h6">Настройка конфигуратора</Typography>
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => navigate("/datasettings")}
            >
              Изменить конфигурацию коллектора отопления
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => navigate("/settingswatter")}
            >
              Изменить конфигурацию коллектора водоснабжения
            </Button>
          </Box>
        )}
      </Container>
    </>
  );
};
