import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  ICalculate,
  EnhancedTableProps,
  Order,
  HeadCell,
} from "../../interfaces";
import { TableBody } from "./TableBody";
import {
  Box,
  Table,
  TableContainer,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Layout } from "../Layout/layout";
import { getPreCatalogM } from "../../store/ThunkCreator";

function SwitchLabels() {
  return (
    <FormControlLabel
      control={<Switch defaultChecked />}
      label="Убрать неиспользуемые"
    />
  );
}

function createSort(
  id: keyof ICalculate,
  numeric: boolean,
  disablePadding: boolean,
  label: string
): HeadCell {
  return { id, numeric, disablePadding, label };
}
const headCells: readonly HeadCell[] = [
  createSort("position", false, true, ""),
  createSort("position", false, true, "Позиция"),
  createSort("typeequipment", false, true, "Тип оборудования"),
  createSort("position", false, true, "количество"),
  createSort("position", false, true, "кол-во копий"),
  createSort("position", false, true, ""),
  createSort("position", false, true, ""),
  createSort("position", false, true, "основное"),
  createSort("pos_spec", false, true, "Поз.спец"),
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ICalculate) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              key={`tsl${headCell.id}`}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  key={`box${headCell.id}`}
                  component="span"
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function TabFinish() {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ICalculate>("position");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const { value: newoffer } = useAppSelector((state) => state.NewOfferReducer);
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPreCatalogM("1"));
  }, []);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ICalculate
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = calculate.map((n) => n.position);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Layout>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <SwitchLabels />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={calculate.length}
              />
              <TableBody order={order} orderBy={orderBy} />
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Layout>
  );
}
