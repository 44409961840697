import { PreResultLayout } from "./PreReslutLayout";
import { useEmptyCalc, useAddList } from "../../../../hooks";
import { PreResultProps } from "./PreResultProps";

export function PreResult(props: PreResultProps) {
  const { isResult, setIsResult } = props;
  const isFixed = useEmptyCalc();
  const [count, name, price, handlerSave, changeHandler] =
    useAddList(setIsResult);

  return (
    <PreResultLayout
      isResult={isResult}
      isFixed={isFixed}
      name={name}
      price={price}
      count={count}
      changeHandler={changeHandler}
      addNode={handlerSave}
    />
  );
}
