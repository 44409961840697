import { Navigate, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  PanelPage,
  ObjectsPage,
  OffersPage,
  MyDocumentPage,
  DataSettingsPage,
  CatalogPage,
  SettingPage,
  SignUpPage,
  OffersAllPage,
  DemoPage,
  WatterSettingsPage,
  SettingWatterPage,
  CatalogWatterPage,
} from "./pages";
import { Layout, LayoutDemo, LayoutOut, LayoutReg } from "./components";
import { useAppDispatch } from "./hooks";
import { getProfile } from "./store/ThunkCreator";
import { useEffect } from "react";
import { Settings } from "./module/Settings/Settings";
import { PumpPage } from "./pages/PumpPage";

export const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProfile("загрузка"));
  }, []);

  return (
    <Routes>
      <Route
        path={"/*"}
        element={
          <Layout>
            <Navigate to="/objects" />
          </Layout>
        }
      />
      <Route
        path={"/panel/*"}
        element={
          <Layout>
            <PanelPage />
          </Layout>
        }
      />
      <Route
        path={"/pump/*"}
        element={
          <Layout>
            <PumpPage />
          </Layout>
        }
      />
      <Route
        path={"/demo/*"}
        element={
          <LayoutDemo>
            <DemoPage />
          </LayoutDemo>
        }
      />
      <Route
        path={"/objects"}
        element={
          <Layout>
            <ObjectsPage />
          </Layout>
        }
      />
      <Route
        path={"/offers/*"}
        element={
          <Layout>
            <OffersPage />
          </Layout>
        }
      />
      <Route
        path={"/alloffers"}
        element={
          <Layout>
            <OffersAllPage />
          </Layout>
        }
      />
      <Route
        path={"/login"}
        element={
          <LayoutOut>
            <LoginPage />
          </LayoutOut>
        }
      />
      <Route
        path={"/register"}
        element={
          <LayoutReg>
            <SignUpPage />
          </LayoutReg>
        }
      />
      <Route
        path={"/settings"}
        element={
          <Layout>
            <Settings />
          </Layout>
        }
      />
      <Route
        path={"/datasettings/*"}
        element={
          <Layout>
            <DataSettingsPage />
          </Layout>
        }
      />
      <Route
        path={"/settingswatter/*"}
        element={
          <Layout>
            <WatterSettingsPage />
          </Layout>
        }
      />
      <Route
        path={"/catalog"}
        element={
          <Layout>
            <CatalogPage />
          </Layout>
        }
      />
      <Route
        path={"/catalogwatter"}
        element={
          <Layout>
            <CatalogWatterPage />
          </Layout>
        }
      />
      <Route
        path={"/settingchoose"}
        element={
          <Layout>
            <SettingPage />
          </Layout>
        }
      />
      <Route
        path={"/settingchoosewatter"}
        element={
          <Layout>
            <SettingWatterPage />
          </Layout>
        }
      />
      <Route path="/pdf" element={<MyDocumentPage />} />
    </Routes>
  );
};
