import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ICalculate, HeadCell } from "../../interfaces";
import {
  Box,
  Table,
  TableContainer,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { RowPanel } from "./RowPanel";
import { Layout } from "../Layout/layout";
import { getPreCatalogM } from "../../store/ThunkCreator";

function createSort(
  id: keyof ICalculate,
  numeric: boolean,
  disablePadding: boolean,
  label: string
): HeadCell {
  return { id, numeric, disablePadding, label };
}
const headCells: readonly HeadCell[] = [
  createSort("position", false, true, ""),
  createSort("position", false, true, "Параметр выбора"),
  createSort("typeequipment", false, true, "Значение по умолчанию"),
  createSort("position", false, true, "Позиция в наименовании"),
  createSort("position", false, true, "Показать в панели"),
];

function EnhancedTableHead(props: any) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const SettingPanel = () => {
  const [selected, setSelected] = useState<readonly string[]>([]);
  const { value } = useAppSelector((state) => state.PanelReducer);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPreCatalogM("2"));
  }, []);
  return (
    <Layout>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                rowCount={value.length}
              />
              <TableBody>
                {value.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <RowPanel key={row.id} row={row} labelId={labelId} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper sx={{ width: "100%", mb: 2, mt: 4 }}>
          Пример наименования: УПК1-3-5
        </Paper>
      </Box>
    </Layout>
  );
};
