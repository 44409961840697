import { ChangeEvent, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { nanoid } from "@reduxjs/toolkit";
import { addChange } from "../../store/ActionCreators";

export function FormDialogChoose(props: any) {
  const { listChange } = useAppSelector((state) => state.ChangeReducer);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState("");

  const dispatch = useAppDispatch();
  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    let valuetem = listChange.map((el) => Object.assign({}, el));
    const idCalc = nanoid();
    valuetem.push({
      id: idCalc,
      name: val,
      meaning: ["не важно"],
    });
    dispatch(addChange(valuetem));
    setVal("");
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Добавить позицию
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Добавить</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование значения в панели"
            type="email"
            fullWidth
            variant="standard"
            value={val}
            onChange={changeHandler}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleAccept}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
