import { createSlice } from "@reduxjs/toolkit";
import { ChangeState } from "../../interfaces";
import { getPreCatalogM, setPreCatalogM } from "../ThunkCreator";

const initialState: ChangeState = {
  listChange: [],
  loading: false,
  error: "",
};

export const ChangeSlice = createSlice({
  name: "listChange",
  initialState,
  reducers: {
    addChange(state, action) {
      state.listChange = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.listChange = action.payload.listchange;
    });
    builder.addCase(getPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.listChange = action.payload.listchange;
    });
    builder.addCase(setPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ChangeSlice.reducer;
