import {
  useState,
  useLayoutEffect,
  ChangeEvent,
  useEffect,
  ReactNode,
} from "react";
import { Routes, Route } from "react-router-dom";
import { Grid } from "@mui/material";
import { ObjectsForOffers } from "../module/objects/ObjectsForOffer/ObjectsForOffers";
import { CreacteObject } from "../module/objects/ObjectsForOffer/CreateObject";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { createObject, getProject } from "../store/ThunkCreator";
import { Filter } from "../module";

export const ObjectsPage = () => {
  const { value: data } = useAppSelector((state) => state.ObjectsReducer);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProject("value"));
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
            columns={12}
            alignItems="flex-start"
          >
            <Grid item xs={4}>
              <CreacteObject />
            </Grid>
            <Grid item xs={8}>
              <Filter />
              <ObjectsForOffers />
            </Grid>
          </Grid>
        }
      />
    </Routes>
  );
};
