import { createSlice } from "@reduxjs/toolkit";
import { DataState, IPreResult } from "../../interfaces";
import { setResult, setResultNoReg } from "../ThunkCreator";

const mok: IPreResult = {
  name: "",
  price: 0,
  specification: [{ position: "", describe: "", count: 0, main: true }],
  choose: [{ name: "", meaning: "" }],
  drawingframe: "",
};

const initialState: DataState<IPreResult> = {
  value: mok,
  loading: false,
  error: "",
};

export const PreResultSlice = createSlice({
  name: "preResult",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setResult.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setResult.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.result;
    });
    builder.addCase(setResult.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setResultNoReg.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setResultNoReg.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.result;
    });
    builder.addCase(setResultNoReg.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default PreResultSlice.reducer;
