import { createSlice } from "@reduxjs/toolkit";
import { DataState, IAccounts } from "../../interfaces";
import { changeRole, getAccounts } from "../ThunkCreator";

// const mokUser: ICompany = [{
//   company: "",
//   inn: 0,
//   address: "",
// }];

export const initialState: DataState<IAccounts[]> = {
  // value: [],
  value: [],
  loading: false,
  error: "",
};

export const ListAccountsSlice = createSlice({
  name: "getAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? "";
    });
    builder.addCase(changeRole.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(changeRole.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(changeRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? "";
    });
  },
});

// changeRole;

export default ListAccountsSlice.reducer;
