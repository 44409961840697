import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactNode } from "react";
import { useAppSelector } from "../../hooks";
import { IOffers } from "../../interfaces";

const OfferHead = () => {
  const { value: account } = useAppSelector((state) => state.UserReducer);
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: "150" }}>
          Коммерческое предложение
        </TableCell>
        <TableCell style={{ minWidth: "80" }}>Дата создания</TableCell>
        <TableCell style={{ minWidth: "80" }}>Тип оборудования</TableCell>
        <TableCell style={{ minWidth: "80" }}>Стоимость</TableCell>
        {account.company === "Энергокомфорт" && (
          <TableCell style={{ minWidth: "80" }}>Скидка</TableCell>
        )}
        <TableCell style={{ minWidth: "80" }}>Автор</TableCell>
        <TableCell style={{ minWidth: "80" }}>Объект</TableCell>
        <TableCell style={{ minWidth: "80" }}>Организация</TableCell>
      </TableRow>
    </TableHead>
  );
};

interface OfferLayoutProps {
  listOffer: ReactNode;
}

export const OfferLayoutFull = (props: OfferLayoutProps) => {
  const { listOffer } = props;
  return (
    <>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="sticky table">
          <OfferHead />
          <TableBody>{listOffer}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface RowResultLayoutProps {
  handlerlink: () => void;
  row: IOffers;
}

export const RowResultOfferLayoutFull = (props: RowResultLayoutProps) => {
  const { value: account } = useAppSelector((state) => state.UserReducer);
  const { handlerlink, row } = props;
  const forname = row.created_on.split("_").splice(1, 2).join("_");
  const date = row.created_on.replaceAll("_", ".");
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`row${row.id}`}
        sx={{ borderBottom: 0 }}
      >
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.code}_{forname}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {date}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.type}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.summ}
        </TableCell>
        {account.company === "Энергокомфорт" && (
          <TableCell
            align={"left"}
            onClick={handlerlink}
            style={{ cursor: "pointer" }}
          >
            {row.discount}
          </TableCell>
        )}

        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.created_by}
        </TableCell>
        <TableCell
          align={"left"}
          onClick={handlerlink}
          style={{ cursor: "pointer" }}
        >
          {row.obj_build}
        </TableCell>
        {row.company ? (
          <TableCell
            align={"left"}
            onClick={handlerlink}
            style={{ cursor: "pointer" }}
          >
            {row.company.company}
          </TableCell>
        ) : (
          <TableCell
            align={"left"}
            onClick={handlerlink}
            style={{ cursor: "pointer" }}
          >
            {account.company}
          </TableCell>
        )}
      </TableRow>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`rowt${row.id}`}
        sx={{ border: 0 }}
      >
        <TableCell />
        <TableCell align={"left"} colSpan={7}>
          КОММЕНТАРИЙ: {row.comment}
        </TableCell>
      </TableRow>
    </>
  );
};
