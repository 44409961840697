import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { NativeSelectType } from "../TabFinish/NativeSelectType";
import { nanoid } from "@reduxjs/toolkit";
import { addValue } from "../../store/ActionCreators";
import { IPreCatalog } from "../../interfaces";

export function FormCreate(props: any) {
  const { precatalog: value } = useAppSelector(
    (state) => state.PreCatalogReducer
  );
  const { catalog } = useAppSelector((state) => state.catalogReducer);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState("");
  const [count, setCount] = useState("1");
  const [copy, setCopy] = useState("1");
  const [type, setType] = useState("Кран шаровой");
  const [equiptem, setEquip] = useState(catalog);
  const dispatch = useAppDispatch();
  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }
  function changeHandlerCount(event: ChangeEvent<HTMLInputElement>) {
    setCount(event.target.value);
  }
  function changeHandlerType(event: ChangeEvent<HTMLSelectElement>) {
    setType(event.target.value);
    console.log(type);
    setEquip(catalog.filter((el) => el.type === event.target.value));
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setEquip(catalog.filter((el) => el.type === type));
  }, [type]);
  const handleClose = () => {
    setOpen(false);
  };
  const deepClone = (obj: IPreCatalog[]) => JSON.parse(JSON.stringify(obj));
  const handleAccept = () => {
    // let valuetem: IPreCatalog[] = value.map((el) => Object.assign({}, el));
    let valuetem: IPreCatalog[] = deepClone(value);
    valuetem.push({
      name: val,
      name_handler: true,
      type_auto: "",
      type_manual: type,
      type_handler: true,
      price_auto: 0,
      price_manual: +count,
      price_handler: true,
      name_spec: " ",
    });
    dispatch(addValue(valuetem));
    setVal("");
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" sx={{ m: 2 }} onClick={handleClickOpen}>
        Добавить оборудование
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Добавить</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Наименование позиции"
            type="email"
            fullWidth
            variant="standard"
            value={val}
            onChange={changeHandler}
          />
          <NativeSelectType
            description={true}
            changeHandlerType={changeHandlerType}
            type={type}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Цена продукции"
            type="email"
            fullWidth
            variant="standard"
            value={count}
            onChange={changeHandlerCount}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleAccept}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
