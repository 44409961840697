import { Route, Routes } from "react-router-dom";
import { Calculate } from "../module";

export const PanelPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Calculate />} />
      <Route path={`/:calcId`} element={<Calculate />} />
    </Routes>
  );
};
