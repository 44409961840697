import { createSlice } from "@reduxjs/toolkit";
import { DataState, IChoose, IOffers, OffersData } from "../../interfaces";
import { offersAll, offersFull } from "../ThunkCreator";

export const initialState: DataState<IOffers[]> = {
  value: [],
  loading: false,
  error: "",
};

export const OffersFullSlice = createSlice({
  name: "offersFull",
  initialState,
  reducers: {
    // addChoose(state, action) {
    //   state.value = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(createOffer.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(createOffer.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.value = action.payload;
    // });
    // builder.addCase(createOffer.rejected, (state) => {
    //   state.loading = false;
    // });
    builder.addCase(offersFull.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(offersFull.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(offersFull.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(offersAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(offersAll.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    });
    builder.addCase(offersAll.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default OffersFullSlice.reducer;
