import { createSlice } from "@reduxjs/toolkit";
import { DataState, IPanel } from "../../interfaces";
import { getPreCatalog, getPreCatalogNoReg } from "../ThunkCreator";

const mok: IPanel[] = [
  {
    id: "1",
    name: "Диаметр вводной группы",
    default: "1",
    position: 0,
    closed: true,
    meaning: [
      { id: "1", name: "DN15", value: "-15", closed: true },
      { id: "2", name: "DN20", value: "-20", closed: true },
      { id: "3", name: "нет", value: "", closed: true },
    ],
  },
  {
    id: "2",
    name: "Материал коллектора",
    default: "4",
    position: 0,
    closed: true,
    meaning: [
      { id: "4", name: "нж", value: "-нж", closed: true },
      { id: "5", name: "ст20", value: "-ст20", closed: true },
      { id: "6", name: "нет", value: "", closed: true },
    ],
  },
];

const initialState: DataState<IPanel[]> = {
  value: [],
  loading: false,
  error: "",
};

export const PanelUserSlice = createSlice({
  name: "listPanelUser",
  initialState,
  reducers: {
    addPanel(state, action) {
      state.value = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalog.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.panel;
    });
    builder.addCase(getPreCatalog.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPreCatalogNoReg.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogNoReg.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.panel;
    });
    builder.addCase(getPreCatalogNoReg.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default PanelUserSlice.reducer;
