import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { columnid, IPreCatalog } from "../../interfaces";
import { addValue } from "../../store/ActionCreators";

interface RowCatalogProps {
  keycolumn: columnid;
  index: number;
  data: IPreCatalog[];
}

export function SwitchLabels(props: RowCatalogProps) {
  const dispatch = useAppDispatch();
  const { keycolumn, index, data } = props;
  const [check, setCheck] = useState(Boolean(data[index][keycolumn]));
  useEffect(() => {
    setCheck(Boolean(data[index][keycolumn]));
  }, [data]);
  const handleChange = () => {
    let value = data.map((el) => Object.assign({}, el));
    if (
      keycolumn === "type_handler" ||
      keycolumn === "price_handler" ||
      keycolumn === "name_handler"
    ) {
      value[index][keycolumn] = !value[index][keycolumn];
      setCheck(value[index][keycolumn]);
    }
    dispatch(addValue(value));
    console.log(Object.assign({}, data[0]));
  };

  return <Switch key={index} checked={check} onChange={handleChange} />;
}
