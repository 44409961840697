import { Box } from "@mui/material";
import { LayoutProps } from "./layoutProps";

export const LayoutMain = ({ children }: LayoutProps): JSX.Element => {
  return (
    <Box sx={{ bgcolor: "#ebe6ef", p: 1 }}>
      <main>{children}</main>
    </Box>
  );
};
