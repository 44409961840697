import { useEffect, useState, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useDebounce } from "../../hooks/debounce";
import { useNavigate } from "react-router-dom";
import { ICalculate, IEquipment, ITerm } from "../../interfaces";
import { addCalculate, addChange } from "../../store/ActionCreators";
import { setPreCatalogM } from "../../store/ThunkCreator";
import { SelectChangeEvent } from "@mui/material";

export const useSaveCalc = (
  calcId: string | undefined,
  accounts: IEquipment
) => {
  const { listChange } = useAppSelector((state) => state.ChangeReducer);
  const { calculate } = useAppSelector((state) => state.ManageCalcReducer);
  const accounting = calculate.map((el) => el.equipment).flat();
  const ValidRoomId = accounting.find(
    (account: IEquipment) => account.id === calcId
  );
  const [account, setAccount] = useState<IEquipment>(accounts);
  const [term, setTerm] = useState(
    ValidRoomId === undefined ? [] : ValidRoomId.terms
  );
  const [position, setPosition] = useState(
    calculate.find((el) => el.id === account.idCalc)?.position
  );

  const debounced = useDebounce<ITerm[]>(term, 500);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleChange = (field: string) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      let temp = term.filter((el) => el.name !== field);

      setTerm([...temp, { name: field, meaning: event.target.value ?? "" }]);
    };
  };
  const handlerChangeTwo = (field: string) => {
    return (newvalue: any) => {
      console.log(newvalue);
      let temp = term.filter((el) => el.name !== field);
      setTerm([...temp, { name: field, meaning: newvalue }]);
    };
  };
  // Сохранение изменений
  const value = {
    value: useAppSelector((state) => state.PreCatalogReducer).precatalog,
    calculate: useAppSelector((state) => state.ManageCalcReducer).calculate,
    listchange: useAppSelector((state) => state.ChangeReducer).listChange,
    panel: useAppSelector((state) => state.PanelReducer).value,
    type: "1",
  };
  async function sethandler() {
    await dispatch(setPreCatalogM(value));
  }
  // ******************
  function addCalc(account: IEquipment): ICalculate[] {
    let valuetem = calculate.map((el) => {
      if (el.id === account.idCalc) {
        let temp = el.equipment.map((eq) => {
          if (eq.id === account.id) {
            return account;
          } else {
            return eq;
          }
        });
        return {
          id: el.id,
          position: el.position,
          main: true, //ИСПРАВИТЬ!
          typeequipment: el.typeequipment,
          count: el.count,
          copy: el.copy,
          pos_spec: el.pos_spec,
          equipment: temp,
        };
      } else {
        return Object.assign({}, el);
      }
    });
    return valuetem;
    // dispatch(addCalculate(valuetem));
  }
  function changeval(term: ITerm[] | []): void {
    const accounting = calculate.map((el: ICalculate) => el.equipment).flat();
    const temp: IEquipment | undefined = accounting.find(
      (account: IEquipment) => account.id === calcId
    );
    if (typeof temp !== "undefined") {
      setAccount({ ...temp, terms: term });
    }
  }
  function changeList(): { meaning: string[]; id: string; name: string }[] {
    let calc = calculate
      .map((el) => el.equipment.map((eq) => eq.terms))
      .flat(2);
    let valuetem = listChange.map((el) => {
      let newmap = [...el.meaning];
      calc.map((element) => {
        if (element.name === el.name && !newmap.includes(element.meaning)) {
          newmap = [...newmap, element.meaning];
        }
        return newmap;
      });
      return Object.assign({}, { ...el, meaning: newmap });
    });
    return valuetem;
    dispatch(addChange(valuetem));
  }
  function savecalc(): void {
    dispatch(addCalculate(addCalc(account)));
    dispatch(addChange(changeList()));
    const listCh = changeList();
    const calclist = addCalc(account);
    const val = {
      value: value.value,
      calculate: calclist,
      listchange: listCh,
      panel: value.panel,
      type: "1",
    };
    dispatch(setPreCatalogM(val));
    // addCalc(account);
    //диспатч listChange, добавить выпадающий список
    // changeList();
    // dispatch(changeCalculate(account, calculate));
    navigate("/datasettings");
  }
  useEffect(() => dispatch(addChange(changeList())), [term]);
  useEffect(() => {
    changeval(debounced);
  }, [debounced]);

  return {
    position: position,
    name: account.name,
    listChange: listChange,
    handleChange: handleChange,
    handlerChangeTwo: handlerChangeTwo,
    term: term,
    savecalc: savecalc,
  };
};
