import {
  NavigateFunction,
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

interface useCustomNavigateProps {
  navigate: NavigateFunction;
  location: Location;
  forpdf: string | undefined;
}

export const useCustomNavigate = (): useCustomNavigateProps => {
  let navigate = useNavigate();
  let location = useLocation();
  const { calcId: forpdf } = useParams();

  return { navigate: navigate, location: location, forpdf: forpdf };
};

export const useEmptyCalc = () => {
  const { calcId } = useParams();
  if (typeof calcId !== "undefined") return true;
  return false;
};
