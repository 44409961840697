import { createSlice } from "@reduxjs/toolkit";
import { PreCatalogState } from "../../interfaces";
import { getPreCatalogM, setPreCatalogM } from "../ThunkCreator";

const initialState: PreCatalogState = {
  precatalog: [],
  loading: false,
  error: "",
};

export const PreCatalogSlice = createSlice({
  name: "precatalog",
  initialState,
  reducers: {
    addValue(state, action) {
      state.precatalog = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.precatalog = action.payload.precatalog;
    });
    builder.addCase(getPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.precatalog = action.payload.precatalog;
    });
    builder.addCase(setPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default PreCatalogSlice.reducer;
