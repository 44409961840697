export const LocationSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="20px"
      height="20px"
      version="1.1"
    //   style={{
    //     shapeRendering: "geometricPrecision",
    //     textRendering: "geometricPrecision",
    //     imageRendering: "auto",
    //     fillRule: "evenodd",
    //     clipRule: "evenodd",
    //   }}
      viewBox="0 0 10000 10000"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_5462296192">
          <path
            className="fil0"
            d="M7163.3 3786.1c-1.32,5.13 -2.39,10.52 -3.19,15.74 -63.68,388.35 -151.9,708.36 -255.11,925.4 -261.98,550.02 -1457.03,2512.97 -1908.8,3250.68 -451.79,-737.71 -1646.75,-2700.66 -1908.72,-3250.68 -103.22,-217.04 -191.44,-537.05 -255.11,-925.33 -0.81,-5.29 -1.87,-10.59 -3.19,-15.81 -43.13,-175.14 -64.97,-355.09 -64.97,-534.79 0,-1230.66 1001.3,-2231.99 2231.99,-2231.99 1230.76,0 2231.99,1001.33 2231.99,2231.99 0,179.7 -21.77,359.58 -64.89,534.79zm-1158.7 4931.33c-27.55,77.22 -368.36,270.85 -1008.4,270.85 -647.46,0 -988.68,-198.21 -1009.07,-268.24 18.26,-67.67 296.46,-234.01 822.35,-267.10 6.13,9.86 10.77,17.51 13.29,21.51 37.1,60.19 102.73,96.80 173.43,96.80 70.77,0 136.41,-36.61 173.50,-96.80 2.52,-4.00 7.17,-11.65 13.29,-21.61 519.05,32.71 796.83,195.28 821.61,264.59zm1556.41 -4841.89c49.73,-204.49 74.86,-414.44 74.86,-624.23 0,-1455.45 -1184.12,-2639.66 -2639.67,-2639.66 -1455.47,0 -2639.66,1184.21 -2639.66,2639.66 0,209.79 25.19,419.74 74.84,624.23 70.60,428.13 167.47,773.58 288.16,1026.84 259.34,544.89 1350.91,2343.53 1852.03,3162.78 -677.46,86.35 -991.95,377.65 -991.95,652.27 0,326.78 443.32,678.52 1416.58,678.52 973.34,0 1416.56,-351.74 1416.56,-678.52 0,-274.62 -314.39,-565.92 -991.94,-652.27 501.20,-819.25 1592.78,-2617.89 1852.12,-3162.78 120.60,-253.16 217.55,-598.71 288.07,-1026.84z"
          fill="#146EAA"
          />
          <path
            className="fil0"
            d="M4996.2 4192.71c-556.3,0 -1008.9,-452.6 -1008.9,-1008.91 0,-556.3 452.6,-1008.97 1008.9,-1008.97 556.38,0 1008.89,452.67 1008.89,1008.97 0,556.31 -452.51,1008.91 -1008.89,1008.91zm0 -2425.55c-781.09,0 -1416.58,635.48 -1416.58,1416.64 0,781.09 635.49,1416.58 1416.58,1416.58 781.16,0 1416.56,-635.49 1416.56,-1416.58 0,-781.16 -635.4,-1416.64 -1416.56,-1416.64z"
            fill="#146EAA"
          />
        </g>
      </g>
    </svg>
  );
};
