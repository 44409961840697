import * as React from "react";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
interface SwitchArchiveProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function SwitchArchive({
  checked,
  setChecked,
}: SwitchArchiveProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      style={{ maxWidth: "200px" }}
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Показать архив"
    />
  );
}
