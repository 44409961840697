export const PhoneSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="20px"
      height="20px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 10000 10000"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      //  xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_6159060000">
          <path
            className="fil0"
            d="M1962.2 2478.39c174.45,-192.79 423.98,-303.32 684.61,-303.32 228.88,0 448.48,84.58 618.45,238.36l397.17 358.94c182.82,165.43 290.16,391.99 302.44,638.01 12.46,246.18 -71.82,482.48 -237.09,665.21l-235.25 260c-0.07,0.07 -0.16,0.07 -0.16,0.07 -112.92,124.88 -257.2,216.34 -417.35,264.54l-71.57 21.47c-45.02,13.57 -81.8,46.21 -100.55,89.37 -18.59,43.19 -17.39,92.41 3.51,134.46l33.27 67.05c521.18,1046.49 1406.73,1859.25 2493.6,2288.43l61.92 24.34c40.62,16.04 86.11,14.43 125.86,-3.91 39.74,-18.6 69.89,-52.5 83.85,-94.01l20.93 -62.48c40.76,-120.82 104.68,-230.07 190.3,-324.54l235.35 -260.25c174.67,-193.11 424.22,-303.79 684.52,-303.79 228.62,0 448.25,84.67 618.38,238.44l397.24 359.35c377.13,341.14 406.42,925.59 65.35,1302.9l-235.48 260.39c-64.31,71.34 -140.21,132.46 -229.19,184.02 -225.76,138.68 -521.25,199.58 -832.8,171.26 -2600.69,-233.27 -4760.8,-2187.03 -5253.18,-4751.48 -104.14,-537.62 91.61,-932.55 248.58,-1066.85 5.11,-4.32 9.97,-9.1 14.45,-14.05l10.93 -11.97c4.7,-5.27 9.02,-10.7 13,-16.44 22.51,-32.55 47.33,-63.93 73.75,-93.13l235.16 -260.39zm-472.02 46.38c-32.87,36.3 -63.84,75.07 -92.25,115.38 -260.23,230.3 -465.94,737.03 -345.05,1361.4 519.03,2703.48 2796.35,4763.19 5538.06,5009.05 59.06,5.37 117.78,7.99 175.96,7.99 314.97,0 612.16,-77.57 848.84,-223.04 114.28,-66.07 216.18,-148.28 302.59,-244.12l235.51 -260.39c459.08,-507.76 419.59,-1294.5 -88.11,-1753.68l-397.24 -359.26c-228.94,-207 -524.62,-320.96 -832.48,-320.96 -350.33,0 -686.13,148.98 -921.23,408.89l-235.32 260.16c-91.99,101.65 -165.58,216.02 -219.44,340.98 -938.46,-394.62 -1708.37,-1099.41 -2184.63,-1999.55 172.53,-69.98 327.75,-178.52 453.75,-317.84l235.34 -260c222.57,-246.02 335.87,-564.11 319.2,-895.37 -16.44,-331.16 -161.03,-636.09 -407.14,-858.81l-397.09 -358.85c-228.87,-206.93 -524.52,-320.87 -832.64,-320.87 -350.64,0 -686.44,148.82 -921.29,408.48l-235.34 260.41z"
            fill="#146EAA"
          />
          <path
            className="fil0 str0"
            d="M8643.26 4952.45c-95.21,0 -172.53,-77.41 -172.53,-172.3 0,-1812.27 -1474.23,-3286.66 -3286.43,-3286.66 -95.12,0 -172.53,-77.25 -172.53,-172.27 0,-95.12 77.41,-172.62 172.53,-172.62 2002.28,0 3631.17,1629.04 3631.17,3631.55 0,94.89 -77.25,172.3 -172.21,172.3zm331.79 -172.3c0,-2090.43 -1700.54,-3791.14 -3790.75,-3791.14 -183.14,0 -332.12,149.05 -332.12,332.21 0,183.04 148.98,331.88 332.12,331.88 1724.16,0 3126.82,1402.82 3126.82,3127.05 0,182.91 148.98,331.89 332.14,331.89 182.97,0 331.79,-148.98 331.79,-331.89z"
            fill="#146EAA"
          />
          <path
            className="fil0 str0"
            d="M7408.83 4993.15c-95.21,0 -172.55,-77.34 -172.55,-172.37 0,-1153.99 -938.76,-2092.86 -2092.74,-2092.86 -94.99,0 -172.3,-77.32 -172.3,-172.53 0,-94.96 77.31,-172.27 172.3,-172.27 1344.13,0 2437.66,1093.5 2437.66,2437.66 0,95.03 -77.34,172.37 -172.37,172.37zm-2265.29 -2769.64c-183,0 -331.92,148.91 -331.92,331.88 0,183.23 148.92,332.14 331.92,332.14 1065.97,0 1933.15,867.18 1933.15,1933.25 0,183.07 148.98,331.95 332.14,331.95 183.04,0 331.96,-148.88 331.96,-331.95 0,-1432.18 -1165.17,-2597.27 -2597.25,-2597.27z"
            fill="#146EAA"
          />
        </g>
      </g>
    </svg>
  );
};
