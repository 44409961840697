import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { resultCalcData } from "../../../interfaces";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { addChangeResult, addChoose } from "../../../store/ActionCreators";

export interface Column {
  id:
    | "name"
    | "count"
    | "size"
    | "density"
    | "edit"
    | "del"
    | "chang"
    | "sumdiscount";
  label: string;
  minWidth?: number;
  align?: "right";
  params?: "discount" | "price";
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  { id: "name", label: "Наименование", minWidth: 320 },
  {
    id: "count",
    label: "Количество",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Прайс за ед.",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "size",
    label: "Стоимость ед. с учетом скидки",
    minWidth: 50,
    align: "right",
    params: "discount",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "sumdiscount",
    label: "Стоимость ед. с учетом скидки",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
  { id: "edit", label: "", minWidth: 30 },
  { id: "chang", label: "", minWidth: 30 },
  { id: "del", label: "", minWidth: 30 },
];

export const ColumnHead = () => {
  return (
    <>
      <TableCell
        sx={{
          minWidth: 320,
          borderRight: "1px solid #005d90",
          borderBottom: "1px solid #005d90",
        }}
      >
        Наименование
      </TableCell>
      <TableCell
        align={"right"}
        sx={{
          minWidth: 50,
          borderRight: "1px solid #005d90",
          borderBottom: "1px solid #005d90",
        }}
      >
        Количество
      </TableCell>
      {/* <TableCell align={"right"} style={{ minWidth: 50 }}>
        Прайс за ед.
      </TableCell> */}
      <TableCell
        align={"right"}
        sx={{
          minWidth: 50,
          borderRight: "1px solid #005d90",
          borderBottom: "1px solid #005d90",
        }}
      >
        Стоимость ед. с учетом скидки
      </TableCell>
      <TableCell
        align={"right"}
        sx={{
          minWidth: 50,
          borderRight: "1px solid #005d90",
          borderBottom: "1px solid #005d90",
        }}
      >
        Стоимость итого с учетом скидки
      </TableCell>
      <TableCell
        align={"right"}
        sx={{ minWidth: 30, borderBottom: "1px solid #005d90" }}
      />
      <TableCell
        align={"right"}
        sx={{ minWidth: 30, borderBottom: "1px solid #005d90" }}
      />
      <TableCell
        align={"right"}
        style={{ minWidth: 30, borderBottom: "1px solid #005d90" }}
      />
    </>
  );
};

export interface SwitchProps {
  column?: Column;
  type?: string;
  row: resultCalcData;
  handler: () => void;
  handlerChange: () => void;
  changeicon: boolean;
}

export interface FormalizationSwitchProps {
  column: string | undefined;
  handler: () => void;
  handlerChange: () => void;
  deleteElement: () => void;
  changeicon: boolean;
  row: any;
}

export const FormalizationSwitch = (
  props: FormalizationSwitchProps
): JSX.Element => {
  const { column, handler, deleteElement, row, changeicon, handlerChange } =
    props;

  switch (column) {
    case "edit": {
      return (
        <Button sx={{ margin: 0, padding: 0 }} onClick={handler}>
          <ArticleIcon />
        </Button>
      );
    }
    case "del": {
      return (
        <Button sx={{ margin: 0, padding: 0 }} onClick={deleteElement}>
          <DeleteIcon />
        </Button>
      );
    }
    case "chang": {
      return (
        <Button sx={{ margin: 0, padding: 0 }} onClick={handlerChange}>
          {!changeicon ? <DoneOutlineIcon /> : <EditIcon />}
        </Button>
      );
    }
    default: {
      return <></>;
    }
  }
};

interface FormPerProps {
  percent: string;
  handlerPer: (event: any) => void;
}

export const FormPercentLayout = (props: FormPerProps) => {
  const { percent, handlerPer } = props;
  return (
    <FormControl sx={{ m: 0, width: "15ch" }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-weight"
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
        value={percent}
        onChange={handlerPer}
        type="number"
        inputProps={{
          "aria-label": "weight",
        }}
      />
    </FormControl>
  );
};

interface RowResultProps {
  listResult: ReactNode;
  open: boolean;
  collapsePanel: ReactNode;
  keyName: string;
}

export const RowResultLayout = (props: RowResultProps) => {
  const { listResult, open, collapsePanel, keyName } = props;
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={keyName}>
        {listResult}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                {collapsePanel}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

interface TableContainerLayoutProps {
  buttonSaveDuble: ReactNode;
  buttonPDF: ReactNode;
  buttonReturn: ReactNode;
  columnHead: ReactNode;
  rowList: ReactNode[];
  summ: string;
  formPersent: ReactNode;
  total: string;
  comments: ReactNode;
}

export const TableContainerLayout = (props: TableContainerLayoutProps) => {
  const {
    buttonSaveDuble,
    buttonPDF,
    buttonReturn,
    columnHead,
    rowList,
    summ,
    formPersent,
    total,
    comments,
  } = props;
  const { value: account, error } = useAppSelector(
    (state) => state.UserReducer
  );
  return (
    <>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>{columnHead}</TableRow>
          </TableHead>
          <TableBody>
            {rowList}
            {/* <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align={"right"}>Прайс</TableCell>
              <TableCell align={"right"}>{summ}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow> */}
            {account.company === "Энергокомфорт" && (
              <TableRow>
                <TableCell sx={{ borderTop: "1px solid #005d90" }} />
                <TableCell sx={{ borderTop: "1px solid #005d90" }} />
                <TableCell sx={{ borderTop: "1px solid #005d90" }} />
                <TableCell
                  align={"right"}
                  sx={{ borderTop: "1px solid #005d90" }}
                >
                  Скидка
                </TableCell>
                <TableCell
                  align={"right"}
                  sx={{ borderTop: "1px solid #005d90" }}
                >
                  {formPersent}
                </TableCell>

                <TableCell sx={{ borderTop: "1px solid #005d90" }} />
                <TableCell sx={{ borderTop: "1px solid #005d90" }} />
              </TableRow>
            )}

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align={"right"}>Итого со кидкой</TableCell>
              <TableCell align={"right"}>{total}</TableCell>
              <TableCell />

              <TableCell />
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell colSpan={2}>{buttonSaveDuble}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell colSpan={2}>{buttonPDF}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell colSpan={2}>{buttonReturn}</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ m: 1 }}>
        <h4>Укажите комментарии:</h4>
        {comments}
      </Box>
    </>
  );
};
