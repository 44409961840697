import { createSlice } from "@reduxjs/toolkit";
import { ManageCalcState } from "../../interfaces";
import { getPreCatalogM, setPreCatalogM } from "../ThunkCreator";

const initialState: ManageCalcState = {
  calculate: [],
  loading: false,
  error: "",
};

export const ManageCalcSlice = createSlice({
  name: "calculate",
  initialState,
  reducers: {
    addCalculate(state, action) {
      state.calculate = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.calculate = action.payload.calculate;
    });
    builder.addCase(getPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(setPreCatalogM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPreCatalogM.fulfilled, (state, action) => {
      state.loading = false;
      state.calculate = action.payload.calculate;
    });
    builder.addCase(setPreCatalogM.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ManageCalcSlice.reducer;
