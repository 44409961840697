import { useEffect, useState } from "react";
import { TableCell, Button, Input, Typography } from "@mui/material";

import { CollapsePanel } from "./CollapseSpec";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  changeCountCalc,
  deleteCalcFromOffers,
} from "../../../store/ThunkCreator";
import {
  Column,
  columns,
  FormalizationSwitch,
  RowResultLayout,
  SwitchProps,
} from "./ResulTableLayout";
import { ChangeIcon } from "../../../store/slices/ChangeResultSlice";
import { addChangeResult, addChoose } from "../../../store/ActionCreators";

const Switchcolumn = (props: SwitchProps): JSX.Element => {
  const { type, row, handler, changeicon, handlerChange } = props;
  const dispatch = useAppDispatch();
  const deleteElement = () => {
    if (row.idpos) dispatch(deleteCalcFromOffers(row.idpos));
  };
  return (
    <FormalizationSwitch
      column={type}
      handler={handler}
      handlerChange={handlerChange}
      deleteElement={deleteElement}
      changeicon={changeicon}
      row={row}
    />
  );
};

const ListRow = (props: any) => {
  const { row, discount, handler, isFixed, changeicon, handlerChange } = props;
  const color = !changeicon ? { bgcolor: "warning.main", color: "white" } : {};

  return (
    <>
      <TableCell
        align={"left"}
        sx={{ ...color, borderRight: "1px solid #005d90" }}
      >
        {row["name"]}
      </TableCell>
      <TableCell
        align={"right"}
        sx={{ ...color, borderRight: "1px solid #005d90" }}
      >
        {!!changeicon ? (
          <>
            <Typography variant="subtitle1">{row["count"]}</Typography>
          </>
        ) : (
          <Input value={row["count"]} sx={{ ...color, width: "30px" }} />
        )}
      </TableCell>
      {/* <TableCell align={"right"}>{row["size"]}</TableCell> */}
      <TableCell
        align={"right"}
        sx={{ ...color, borderRight: "1px solid #005d90" }}
      >
        {(+row["size"] * (1 - +discount / 100))
          .toFixed(2)
          .toString()
          .replace(".", ",")}
      </TableCell>
      <TableCell
        align={"right"}
        sx={{ ...color, borderRight: "1px solid #005d90" }}
      >
        {(+row["size"] * (1 - +discount / 100) * +row["count"])
          .toFixed(2)
          .toString()
          .replace(".", ",")}
      </TableCell>
      <TableCell align={"right"}>
        {!isFixed ? (
          <Switchcolumn
            changeicon={changeicon}
            handler={handler}
            handlerChange={handlerChange}
            type={"edit"}
            row={row}
          />
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell>
        {!isFixed ? (
          <Switchcolumn
            changeicon={changeicon}
            handler={handler}
            handlerChange={handlerChange}
            type={"chang"}
            row={row}
          />
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell align={"right"}>
        {!isFixed ? (
          <Switchcolumn
            changeicon={changeicon}
            handler={handler}
            handlerChange={handlerChange}
            type={"del"}
            row={row}
          />
        ) : (
          <></>
        )}
      </TableCell>
    </>
  );
};

export function RowResult(props: any) {
  const { row, isFixed, discount, account } = props;
  const [open, setOpen] = useState(false);
  const [dis, setDis] = useState<Boolean>(true);
  const handler = () => {
    setOpen(!open);
  };
  const { value: changeicon } = useAppSelector(
    (state) => state.ChangeResultReducer
  );
  const dispatch = useAppDispatch();
  const handlerChange = () => {
    if (changeicon.find((el: any) => el.idpos === row.idpos)?.value === true) {
      dispatch(addChoose(row.choose));
    }
    if (changeicon.find((el: any) => el.idpos === row.idpos)?.value === false) {
      // TODO: ФУНКЦИЯ ЗАМЕНЫ КОЛИЧЕСТВА
      // dispatch(changeCountCalc({ idpos: row.idpos, count: "5" }));
    }
    const val = changeicon.map((el) => {
      if (el.idpos === row.idpos) {
        return { ...el, value: !el.value };
      } else {
        return { ...el, value: true };
      }
    });
    dispatch(addChangeResult(val));
  };

  const handlerdic = () => {
    if (dis === false) {
      setDis(true);
    }
    if (dis === true) {
      setDis(false);
    }
  };

  return (
    <RowResultLayout
      listResult={
        <ListRow
          row={row}
          discount={discount}
          handler={handler}
          changeicon={
            changeicon.find((el: any) => el.idpos === row.idpos)?.value ?? true
          }
          handlerChange={handlerChange}
          isFixed={isFixed}
        />
      }
      open={open}
      collapsePanel={<CollapsePanel row={row} account={account} />}
      keyName={row.name}
    />
  );
}
