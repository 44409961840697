import { TableCell, TableRow } from "@mui/material";
import { columns } from "./createData";
import { SwitchLabels } from "./SwitchLabels";
import { InputTab } from "./InputTab";
import { Column, IPreCatalog } from "../../interfaces";
import { ReactNode } from "react";

function switchfunc(columnid: string, value: any, index: any, data: any) {
  switch (columnid) {
    case "name":
      return (
        <InputTab
          columnid={columnid}
          key={columnid}
          value={value}
          index={index}
          data={data}
          handler="name_handler"
        />
      );
    case "type_manual":
      return (
        <InputTab
          columnid={columnid}
          key={columnid}
          value={value}
          index={index}
          data={data}
          handler="type_handler"
        />
      );
    case "price_manual":
      return (
        <InputTab
          columnid={columnid}
          key={columnid}
          value={value}
          index={index}
          data={data}
          handler="price_handler"
        />
      );
    case "name_spec":
      return (
        <InputTab
          columnid={columnid}
          key={columnid}
          value={value}
          index={index}
          data={data}
          handler="name_spec"
        />
      );
    default:
      return value;
  }
}

interface RowCatalogProps {
  row: IPreCatalog;
  data: IPreCatalog[];
  index: number;
}
type ICallback = (
  column: Column,
  other: string | number | boolean
) => ReactNode;

const proxyRowVars =
  (callback: ICallback, row: IPreCatalog) => (column: Column) => {
    const value = row[column.id];
    return callback(column, value);
  };

export function RowCatalog(props: RowCatalogProps) {
  const { row, data, index } = props;
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map(
        proxyRowVars(
          (column, value) => (
            <TableCell key={column.id} align={column.align}>
              {typeof value === "boolean" ? (
                <SwitchLabels
                  key={column.id}
                  keycolumn={column.id}
                  index={index}
                  data={data}
                />
              ) : (
                switchfunc(column.id, value, index, data)
              )}
            </TableCell>
          ),
          row
        )
      )}
    </TableRow>
  );
}
