import axios, { AxiosInstance } from "axios";
import { axiosApiProps } from "../interfaces";
import { baseURL } from "./baseUrl";

export const accessToken = localStorage.getItem("token");
export const refreshToken = localStorage.getItem("refresh") || "";

const cabinet: AxiosInstance = axios.create({
  withCredentials: true,
  // baseURL: baseURL(),
  timeout: 5000,
  headers: {
    Authorization: accessToken ? "JWT " + accessToken : false,
    "Content-Type": "application/json",
    accept: "application/json",
  },
  xsrfCookieName: "X-CSRFToken",
  xsrfHeaderName: "X-CSRFToken",
});
const instance: AxiosInstance = axios.create({
  withCredentials: true,
  // baseURL: baseURL(),
  timeout: 5000,
  headers: {
    Authorization: false,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const nonregister: AxiosInstance = axios.create({
  withCredentials: true,
  // baseURL: baseURL(),
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  xsrfCookieName: "X-CSRFToken",
  xsrfHeaderName: "X-CSRFToken",
});

export const axiosApi: axiosApiProps = {
  cabinet: cabinet,
  instance: instance,
  nonregister: nonregister,
};

cabinet.interceptors.request.use((config: any) => {
  config.headers.Authorization = `JWT ${localStorage.getItem("token")}` ?? "";
  return config;
});

cabinet.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      error.config &&
      !error.config._isRetry &&
      originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await cabinet.post(`auth/jwt/refresh`, {
          refresh: refreshToken,
        });
        localStorage.setItem("token", response.data.access);
        return cabinet.request(originalRequest);
      } catch (e) {
        console.log("НЕ АВТОРИЗОВАН");
      }
    }
    throw error;
  }
);
