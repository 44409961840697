import { FormControl, NativeSelect, InputLabel } from "@mui/material";
import { ChangeEvent } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";

interface NativeSelectProps {
  type: string;
  changeHandlerType: (event: ChangeEvent<HTMLSelectElement>) => void;
  description: Boolean;
}

export function NativeSelectType(props: NativeSelectProps) {
  const { types } = useAppSelector((state) => state.typeEquipmentReducer);
  const dispatch = useAppDispatch();
  const { type, changeHandlerType, description } = props;
  return (
    <FormControl variant="outlined" fullWidth>
      {description ? (
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Выбери тип оборудования
        </InputLabel>
      ) : (
        <></>
      )}
      <NativeSelect
        disableUnderline
        variant="outlined"
        defaultValue={type}
        onChange={changeHandlerType}
        inputProps={{
          name: "Выберите тип оборудования",
          id: "uncontrolled-native",
        }}
      >
        {types.map((rowtype) => (
          <option key={rowtype.name} value={rowtype.name}>
            {rowtype.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
