import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { getProject } from "../../../store/ThunkCreator";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { api } from "../../../api";
import { Filter } from "../../filter";

export const CreacteObject = () => {
  let navigate = useNavigate();
  const { value: valueProject } = useAppSelector(
    (state) => state.ObjectsReducer
  );
  const { value: user } = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [client, setClient] = useState("");
  const createAccountingByName = (event: any) => {
    setName(event.target.value);
  };
  const createAccountingByAddress = (event: any) => {
    setAddress(event.target.value);
  };
  const createAccountingByClient = (event: any) => {
    setClient(event.target.value);
  };

  function handleCloseTrue() {
    const projects = valueProject.map((el) => el.name);
    const isValidName = !projects.includes(name);
    if (name && isValidName) {
      let value = {
        name: name,
        address: address,
        client: client,
        company: user.idCompany,
        user: user.idUsername,
      };
      api.createObjectSimple(value).then((resp) => {
        setNav(resp.id);
      });
      dispatch(getProject("value"));
    } else {
      alert("Такой объект уже существует");
    }
    setName("");
    setOpen(false);
  }
  useEffect(() => {
    if (nav) {
      setTimeout(() => navigate(`/offers/${nav}`), 200);
    }
  }, [nav]);

  function hand(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === "Enter") {
      const projects = valueProject.map((el) => el.name);
      const isValidName = !projects.includes(name);
      if (name && isValidName) {
        let value = {
          name: name,
          address: address,
          client: client,
          company: user.idCompany,
          user: user.idUsername,
        };
        api.createObjectSimple(value).then((resp) => {
          setNav(resp.id);
        });
        dispatch(getProject("value"));
      } else {
        alert("Такой объект уже существует");
      }
      setName("");
      setOpen(false);
    }
  }
  useEffect(() => {
    if (nav) {
      setTimeout(() => navigate(`/offers/${nav}`), 200);
    }
  }, [nav]);

  return (
    <div>
      <DialogContent>
        <DialogActions>
          <Button variant="contained" fullWidth onClick={handleCloseTrue}>
            Создать новый объект
          </Button>
        </DialogActions>
        {/* <DialogContentText>Создание нового объекта</DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Наименование проекта"
          onChange={createAccountingByName}
          type="text"
          fullWidth
          variant="standard"
          // inputRef={ref}
          onKeyDown={hand}
        />
        <TextField
          margin="dense"
          id="address"
          label="Адрес"
          onChange={createAccountingByAddress}
          type="text"
          fullWidth
          variant="standard"
          onKeyDown={hand}
        />
        <TextField
          margin="dense"
          id="client"
          label="Заказчик"
          onChange={createAccountingByClient}
          type="text"
          fullWidth
          variant="standard"
          onKeyDown={hand}
        />
      </DialogContent>

      {/* <Filter /> */}
    </div>
  );
};
