import { createSlice } from "@reduxjs/toolkit";
import { DataState, IExceptCalc } from "../../interfaces";
import { getPreCatalog, getPreCatalogNoReg } from "../ThunkCreator";

const initialState: DataState<IExceptCalc[] | []> = {
  value: [],
  loading: false,
  error: "",
};

export const ExceptCalcSlice = createSlice({
  name: "listExceptCalc",
  initialState,
  reducers: {
    // addChoose(state, action) {
    //   state.listExceptCalc = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreCatalog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalog.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.exceptcalc;
    });
    builder.addCase(getPreCatalog.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPreCatalogNoReg.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreCatalogNoReg.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.exceptcalc;
    });
    builder.addCase(getPreCatalogNoReg.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default ExceptCalcSlice.reducer;
