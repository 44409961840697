import Button from "@mui/material/Button";
import { BasicTabs } from "./BasicTabs";
import { LayoutProps } from "./layoutProps";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getPreCatalogM, setPreCatalogM } from "../../store/ThunkCreator";

export function Layout({ children }: LayoutProps) {
  const value = {
    value: useAppSelector((state) => state.PreCatalogReducer).precatalog,
    calculate: useAppSelector((state) => state.ManageCalcReducer).calculate,
    listchange: useAppSelector((state) => state.ChangeReducer).listChange,
    panel: useAppSelector((state) => state.PanelReducer).value,
    type: "1",
  };
  const dispatch = useAppDispatch();
  async function handler() {
    await dispatch(getPreCatalogM(value.type));
  }
  async function sethandler() {
    await dispatch(setPreCatalogM(value));
  }

  return (
    <>
      <div>
        {/* <Button onClick={handler}>Загрузить данный с сервера</Button> */}
        <Button onClick={sethandler}>Сохранить данные</Button>
      </div>
      <BasicTabs>{children}</BasicTabs>
    </>
  );
}
