import {
  TableBody as MUITableBody,
  TableCell,
  TableRow,
  TableHead,
  Input,
} from "@mui/material";
import { Switch } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IPanel } from "../../interfaces";
import { addPanel } from "../../store/ActionCreators";

interface createheadtyperowsProps {
  id: string;
  name: string;
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined;
}

function createheadtyperows(
  id: string,
  name: string,
  align: "left" | "inherit" | "center" | "right" | "justify" | undefined
): createheadtyperowsProps {
  return { id, name, align };
}

const headtyperows = [
  createheadtyperows("name", "Выбираемое значение", "left"),
  createheadtyperows("value", "В наименовании", "left"),
  createheadtyperows("closed", "Использовать", "left"),
];

const InputName = (props: any) => {
  const { value } = useAppSelector((state) => state.PanelReducer);
  const dispatch = useAppDispatch();
  const { defaultValue, row, headrow, key } = props;
  const changeHandlerType = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    console.log(row);
    let mean = { ...headrow, value: event.target.value };
    let meaning = row.meaning.map((el: any) => {
      if (mean.id === el.id) {
        return mean;
      }
      return el;
    });

    let valPanel = value.map((el) => {
      if (el.id === row.id) {
        return { ...el, meaning: meaning };
      }
      return el;
    });
    dispatch(addPanel(valPanel));
  };
  return (
    <Input key={key} defaultValue={defaultValue} onChange={changeHandlerType} />
  );
};

const CustomSwitch = (props: any) => {
  const { keyswitch, ident, closed, meaning, id } = props;
  const { value } = useAppSelector((state) => state.PanelReducer);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(closed);
  const changeHandlerType = (event: React.ChangeEvent<HTMLInputElement>) => {
    let meaningred = meaning.map((el: any) => {
      if (el.id === id) {
        return { ...el, closed: event.target.checked };
      } else {
        return el;
      }
    });
    let valPanel = value.map((el) => {
      if (el.id === ident) {
        return { ...el, meaning: meaningred };
      } else {
        return { ...el };
      }
    });
    dispatch(addPanel(valPanel));
    setOpen(event.target.checked);
  };
  return <Switch key={keyswitch} checked={open} onChange={changeHandlerType} />;
};

const funcswitch = (value: any, historyRow: any, row: any) => {
  switch (value.id) {
    case "name":
      return <>{historyRow[value.id]}</>;
    case "value":
      return (
        <InputName
          defaultValue={historyRow[value.id]}
          row={row}
          headrow={historyRow}
          key={`input${historyRow.name}`}
        />
      );
    case "closed":
      return (
        <CustomSwitch
          keyswitch={`switch${historyRow.name}`}
          ident={row.id}
          id={historyRow.id}
          closed={historyRow.closed}
          meaning={row.meaning}
        />
      );
  }
};

export function CollapsePanel(props: { row: IPanel }) {
  const { row } = props;
  return (
    <>
      <TableHead>
        <TableRow>
          {headtyperows.map((headrow: createheadtyperowsProps, index) => (
            <TableCell key={headrow.id} align={headrow.align}>
              {headrow.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <MUITableBody>
        {row.meaning.map((historyRow: any) => (
          <TableRow key={historyRow.name}>
            {headtyperows.map((headrow: any, index) => (
              <TableCell
                key={`${historyRow.id}${headrow.id}`}
                align={headrow.align}
              >
                {funcswitch(headrow, historyRow, row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </MUITableBody>
    </>
  );
}
